import React, { useRef, useState, useEffect } from "react";

const alphabetArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];;

const SurveyRelationalItem = (props) => {
  const itemData = props.questionData || {};
  console.log(props)
  const inputRef = useRef([]);

  const handleChange = (index, data, el) => {

    const value = el.target.value;
    const max = Object.values(props.questionData.second_column).length;


    if(value < 1 || value > max) {
      inputRef.current[index].value = '';
    }else {
      inputRef.current[index].value = value;
    }

    const letter = alphabetArray[index]

    const [key, item] = data;

    console.log({[key]: {value}});
    props.handleClick({[key]: value});

  };



  return (
    <>
      {props.questionData.item_context && (
        <p className="c-item-format-style-12__item-context">{props.questionData.item_context}</p>
      )}

      {props.questionData.img_context && (
        <img
          className="c-item-format-style-12__item-context-img"
          src={props.questionData.img_context}
        />
      )}

      {props.questionData.title && props.questionData.intro_title && (
        <p className="c-item-format-style-12__item-title">{props.questionData.title}</p>
      )}

      <div className="c-item-format-style-14__columns-container">
        <ul className="c-item-format-style-14__column c-item-format-style-14__column-first">
          {Object.values(props.questionData.first_column).map((item, index) => {
            return (
              <li className="c-item-format-style-14__column-item" key={item.text}>
                {item.text && <p> {item.text} </p>}
                {item.img && <img src={item.img} alt={item.text} />}
              </li>
            );
          })}
        </ul>
        <ul className="c-item-format-style-14__column c-item-format-style-14__column-second">
          {Object.values(props.questionData.second_column).map((item, index) => {
            return (
              <li className="c-item-format-style-14__column-item" key={item.text}>
                {item.text && <p> {item.text} </p>}
                {item.img && <img src={item.img} alt={item.text} />}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="c-item-format-style-14-answer">
        {Object.entries(props.questionData.first_column).map(([key,item], index) => {
          return (
            <div className="c-item-format-style-14-answer-container" key={index}>
              <div className="c-item-format-style-14-answer-letter">{alphabetArray[index]}</div>
              <div className="c-item-format-style-14-answer-input">
                <input
                  ref={e => inputRef.current[index] = e} 
                  type="number"
                  min="1"
                  defaultValue={props.selectedItems[index] || ""}
                  max={Object.values(props.questionData.second_column).length}
                  onChange={(el) => handleChange(index, [key, item], el)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SurveyRelationalItem;
