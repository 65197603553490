// Dependencies
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";


import ProcedureTableResults from "./ProcedureTableResults";
import IllustrationLineSeparator from "../../../components/IllustrationLineSeparator";


const ProcedureUserResults = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
        {props.data.data.length > 0 ? (
          <React.Fragment>

            <ProcedureTableResults 
              data={props.data.data}
              getScoreUserFolder={(id, procedure_id) => {props.getScoreUserFolder(id, procedure_id)}}
              ids={[]}
              allIds={[]}
              // canManage={
              //   props.organization &&
              //   props.organization.user_role !== Roles.admin.READER
              // }
              openVideoModal={(video_data) => props.openVideoModal(video_data)}
              goTo={(id) =>
                props.history.push(
                  `/admin/organizations/${props.organization.data.id}/procedures/${props.procedure.id}/procedure_users/${id}`
                )
              }
            />
          </React.Fragment>
        ) : (
          <>
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
              No data yet 
            </h2>
            <IllustrationLineSeparator />
          </>
        )}
    </motion.div>
  );
};

export default ProcedureUserResults;
