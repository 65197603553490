// Dependencies
import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import Switch from "react-switch";

const ProcedureUserSettings = (props) => {
  const [settingsForm, setSettingsForm] = useState({});

  const data = props.data
  console.log(data)
  useEffect(() => {
    let temp_settings = {folders: {}, proctoring: data.data.proctoring}
    data.data && data.data.folders.map((folder, index) => {
      temp_settings["folders"][""+folder.id] = folder.enabled
    })
    setSettingsForm(temp_settings)
  },[]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <p> From this section, you will be able to enable or disable a survey for this user.</p>
      <p>And also you can enable or disable proctoring for all the surveys.</p>
      <p style={{marginBottom: 30}}><u>Only is editable while the user has not entered the platform.</u></p>

      {
        data.data && data.data.folders.map((folder, index) => {
          return (
            <div className="c-switch__container">
              <label className="c-switch__label">{folder.name}</label>
              <Switch
                onChange={(checked) => {
                  setSettingsForm({...settingsForm, "folders": {...settingsForm["folders"], [folder.id]: checked}})
                }}
                disabled={!data.data.editable}
                checked={settingsForm["folders"] && settingsForm["folders"][folder.id] !== undefined ? settingsForm["folders"][folder.id] : folder.enabled}
                className={`c-switch c-switch-${index}`}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#1dd1a1"
                offColor="#121a22"
              />
            </div>
          )

        })
      }
      <div className="c-switch__container">
        <label className="c-switch__label">Proctoring</label>
        <Switch
          onChange={(checked) => {
            setSettingsForm({...settingsForm, ["proctoring"]:checked})
          }}
          checked={settingsForm["proctoring"] !== undefined ? settingsForm["proctoring"] : data.proctoring}
          className={`c-switch c-switch-proctoring`}
          checkedIcon={false}
          disabled={!data.data.editable}
          uncheckedIcon={false}
          onColor="#1dd1a1"
          offColor="#121a22"
        />
      </div>
      <button
        type="button"
        disabled={!data.data.editable}
        className="c-button c-button--margin-zero"
        onClick={() => {
          props.fetchUpateProcedureUser(settingsForm);
        }}
      >
        <span className="c-button__text">Save</span>
      </button>
    </motion.div>
  );
};

export default ProcedureUserSettings;
