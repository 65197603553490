import {FETCH_SURVEY_OK, FETCH_SURVEY_SCORE, FETCH_LOGOUT, SET_SPECIAL, SET_ORGANIZATION_DATA} from './actions';

export default function surveyReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_SURVEY_OK:
      return {...state, ...action.data.attributes}
    case FETCH_SURVEY_SCORE:
      return {...state, score: action.data }
    case FETCH_LOGOUT:
      return {}; 
    case SET_SPECIAL:
      return {...state, special_organization: action.special }; 
    case SET_ORGANIZATION_DATA: 
       return {...state, ...action.data}
    default:
      return state
  }
}
