export const config = {
  'development': {
    apiHost: "localhost",
    apiStorage: "https://t3-storage-stag.grupofacthum.com/api/v1",    
    
    // apiStorage: "http://192.168.0.166:3000/api/v1",    
    client: {
      url: 'http://localhost:3000/api'
    },
    originHost: 'http://localhost:3000',
  },
  'staging': {
    apiStorage: "https://t3-storage-stag.grupofacthum.com/api/v1",    
    apiHost: "staging.host",
    client: {
      url: 'https://t3-stag.grupofacthum.com/api'
    },
    originHost: 'https://t3-stag.grupofacthum.com',
  },
  'production': {
    apiHost: "production.host",
    apiStorage: "https://t3-storage.grupofacthum.com/api/v1",
    client: {
      url: 'https://t3.grupofacthum.com/api'
    },
    originHost: 'https://t3.grupofacthum.com',
  }
}

export default config;
