import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

// Actions
import { fetchSurveys, setUserFolderLanguage } from "./actions";
import {setUserLanguage} from '../users/actions'

// Components
import SurveyItem from "./components/SurveyItem";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import detectLanguage from "../../helpers/detectLanguage";
//localize
//
import uaxLogo from '../../../../assets/images/logos/uax_logo.svg'

import internalization from "../../config/localize"
import Modal from "../../components/Modal";

class SurveyIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      loaded: false,
      language: "es",
      showInfoModal: false
    };
  }

  componentDidMount() {

    $(window).scrollTop(0);
    let is_admin = localStorage.getItem('admin')

    if(is_admin == "true"){
      this.props.history.push("/admin")
    }else{
      this.props.fetchSurveys((data) => this._success(data));  
    }    
  }

  changeUserFolderLanguage(id, language, success) {
    this.props.setUserFolderLanguage(id, {language: language}, success)
  }
  _success(data){
    console.log("surveyindex", data)

    let root = document.documentElement;    
    root.style.setProperty("--primary-color", data.colors.primary_color);
    root.style.setProperty("--secondary-color", data.colors.secondary_color);
    let language = data.user_language
    if(data.user_language == null || data.user_language == undefined){
      language = detectLanguage()  
    }
    // Showing Info modal before seeing survyes listing
    if(data.uax_modal_enabled){
      const showInfoModal = localStorage.getItem('showInfoModal')
      if(!showInfoModal ){
        this.setState({showInfoModal: true})
        localStorage.setItem('showInfoModal', true)
      }
    }
    internalization.setLanguage(language)
    this.props.setUserLanguage(language)
    this.setState({ surveys: data.surveys.data, loaded: true, language: language })

    console.log("data", data)

    this.cleanBodyGlassEffect();

  }

  cleanBodyGlassEffect() {
    if(document.querySelector('#o-root').classList.contains('has-glass-effect')){
      document.querySelector('#o-root').classList.remove('has-glass-effect');
      document.querySelector('#o-root').style.backgroundImage = null;
    }
  }


  surveyClickStartButton(item){
    
    if(item.attributes.external_service === "criteria"){
       window.open(item.attributes.survey_settings.url, '_blank').focus();
    }
    else {
      this.props.history.push(`/survey/${item.attributes.token}`)  
    }
  }

  _checkSurveyEnabled(surveys, index) {
    return true
  }

  _renderListing(surveys) {
    return surveys.map((item, index) => {
      let check_enabled = this._checkSurveyEnabled(surveys, index)
      return (
        <div className="c-test" key={index}>
          <SurveyItem
            title={item.attributes.title}
            enabled_languages={item.attributes.languages}
            current_user_folder_language={item.attributes.current_user_folder_language}
            description={this.props.special_organization ? this._getSpecialOrganizationDescription(item.attributes.description) : item.attributes.description}
            image_url={item.attributes.image_url}
            ellipsis={false}
            closed={item.attributes.progress === 100 || item.attributes["is_ended?"]}
            enabled={check_enabled}
            ended={item.attributes["is_ended?"]}
            internalization={internalization}
            report_url={item.attributes.report_url}
            progress={item.attributes.progress}
            external_service={item.attributes.external_service}
            survey_settings={item.attributes.survey_settings}
            handleClick={() => { this.surveyClickStartButton(item) }}
            setUserFolderLanguageOption={(language, success) => this.changeUserFolderLanguage(item.attributes.user_folder_id, language, success) }
          />
          {
            (index + 1) < (surveys.length) &&
              <IllustrationLineSeparator className="c-test__separator" />
          }
        </div>
      );
    });
  };

  _getSpecialOrganizationTitle = () => {
    if(this.state.surveys.length > 0){
      let survey = this.state.surveys[0];
      if(survey.attributes.progress === 100){
        if(this.state.language == "en"){
          return "Many thanks for finishing the survey."
        }else{
          return "Muchas gracias por finalizar el cuestionario"   
        }
        
      }
    }
    if(this.state.language == "en"){
      return 'Welcome at Mahou San Miguel survey.' 
    }else{
      return 'Bienvenid@ a la evaluación de competencias de Mahou San Miguel.' 
    }
  }

  _getSpecialOrganizationDescription = (description) => {
    if(this.state.surveys.length > 0){
      let survey = this.state.surveys[0];
      if(survey.attributes.progress === 100){
        if(this.state.language == "en"){
          return "Many thanks for having spent a few minutes answering these questions! Thanks to you we are closer to having a better tool."
        }else{
          return "¡Muchas gracias por dedicar unos minutos a contestar nuestro test! Gracias a ti estamos más cerca de tener una mejor herramienta"  
        }
        
      }
    }
    return description
  }

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >

        {this.state.showInfoModal && (
          <Modal
            isVisible={true}
            medium
          >
            <img src={uaxLogo} width={200} style={{ marginBottom: 12 }}/>
            <h6>Bienvenido a las pruebas de admisión de la Universidad Alfonso X el Sabio</h6>
            <br/>
            <p>Antes de comenzar, debes tener en cuenta que se ha personalizado el grupo de pruebas que te corresponden realizar según tu vía de acceso y titulación solicitada.</p>
            <br/>
            <p>Nos gustaría informarte de la importancia de las mismas, ya que nos ayudarán a conocerte mejor para apoyarte en tu desarrollo académico y personal.</p>
            <br/>
            <p>Para obtener los mejores resultados te recomendamos realizarlas en un entorno tranquilo y sin interrupciones, donde puedas concentrarte y demostrar tus habilidades de manera óptima. 
              Es importante que te sientas cómodo y relajado durante la prueba.</p>
            <br/>

            <button
              type="button"
              className="c-button c-modal__button"
              onClick={() => this.setState({showInfoModal: false})}
            >
              <span className="c-button__text">Confirmar</span>
            </button>
          </Modal>
        )}

        <div className="o-container">
          <h1 className="o-margin--bottom-size-xl o-margin--bottom-size-xxl@md-and-up helloooo">
            {this.state.surveys && this.state.surveys.length > 0 ? (this.props.special_organization ? this._getSpecialOrganizationTitle() : internalization.survey.title) : internalization.survey.loading}
          </h1>
          {this._renderListing(this.state.surveys)}
          {
            this.state.surveys.length == 0 && this.state.loaded &&
              <h3>No tienes pruebas todavía</h3>
          } 
        </div>
      </motion.section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    special_organization: state.survey.special_organization
  }
};

const mapDispatchToProps = {
  fetchSurveys,
  setUserLanguage,
  setUserFolderLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyIndex);
