import PropTypes from "prop-types";
import React, {useState, useEffect, useRef} from "react";
import classNames from "classnames";

// Components
import Tick from "./Tick";

const Checkbox = (props) =>  {

  const [text, setText] = useState("");
  const [checked, setChecked] = useState(false);
  const [prevChecked, setPrevChecked] = useState(false);
  const [value, setValue] = useState(null);
  const [checkBoxClasses, setCheckBoxClasses] = useState("c-checkbox");
  const [tickClassName, setTickClassName] = useState("c-checkbox__tick");
  const [spanTextClasses, setSpanTextClasses] = useState("c-checkbox__text");

  const checkbox = useRef(null);

  useEffect(() => {
    props.text && setText(props.text);
    props.value && setValue(props.value)
   // checked !== props.checked  && setChecked(props.checked)
  }, [props])

  // getDerivedStateFromProps
  if(props.checked !== checked) {
   setChecked(props.checked)
  }

  useEffect(() => {

    setCheckBoxClasses(
      classNames(
        "c-checkbox",
        props.className,
        props.big && "c-checkbox--big",
        checked && "is-active",
        props.disabled && 'disabled'
      ));

    setTickClassName(
      classNames(
        "c-checkbox__tick",
        props.correct !== undefined && (props.correct ? "c-tick--bg-success" : "c-tick--bg-error"),
        props.item_format_style !== undefined && props.item_format_style == 11 ? "c-tick--square": "",
        props.tickClassName
      ));

    setSpanTextClasses(
      classNames(
        "c-checkbox__text",
        props.correct !== undefined && "o-font--bold",
        props.textClassName && props.textClassName
      ));

  }, [props, checked])

  return (
    <div
      ref={checkbox}
      className={checkBoxClasses}
      onClick={
        props.big ? () => {} : () => props.onClick(text, checked, value)
      }
      data-cursor-hover
      data-checked={props.checked}
      data-value={props.value}
    >
      <Tick
        big={props.big}
        isActive={props.checked}
        className={tickClassName}
        background
        color={props.correct !== undefined ? (props.correct ? "#1dd1a1" : "red") : undefined}
        groupClass={props.groupClass}
      />
      <span dangerouslySetInnerHTML={{__html: props.text}} className={spanTextClasses}></span>
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  big: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.node,
  radioButtonBehavior: PropTypes.bool,
  tickClassName: PropTypes.string,
};

export default Checkbox;


