import React, { useRef, useState, useEffect } from "react";

const SurveyTrueFalseItem = (props) => {

  const containerRef = useRef([]);

  const _handleCheckbox = (index, data, el) => {

    let boxes = containerRef.current[index].querySelectorAll(`.c-true-false-box`);
    boxes.forEach((el) => el.classList.remove("is-selected"));
    el.currentTarget.classList.add("is-selected");
    data["value"] = el.currentTarget.getAttribute("value")
    return props.handleClick({ [index]: data });
  };

  useEffect(() => {
    if (props.selectedItems && Object.keys(props.selectedItems).length > 0){
      setTimeout(() => {
        let list = document.getElementsByClassName('c-true-false-boxes')
        for (var i = 0; i < list.length; i++) {
          try{
            if(props.selectedItems[i].value === "0"){
              list[i].childNodes[1].classList.add("is-selected")
            }else{
              list[i].childNodes[0].classList.add("is-selected")
            }
          }catch(e){
            console.log(e)
          }
            
        }
      }, 1000)
    }
  }, []);
  return (
    <>

      {props.questionData.item_context && (
        <p className="c-item-format-style-12__item-context">{props.questionData.item_context}</p>
      )}

      {props.questionData.img_context && (
        <img
          className="c-item-format-style-12__item-context-img"
          src={props.questionData.img_context}
        />
      )}

      {props.questionData.title && props.questionData.intro_title && (
        <p className="c-item-format-style-12__item-title">{props.questionData.title}</p>
      )}

      {Object.values(props.questionData.items).map((item, index) => {
        return (
          <div className="c-item-format-style-13__container">
            <div>
              {item && item.img && <img src={item.img} alt={item.text} />}
              {item && item.text && <p>{item.text}</p>}
            </div>
            <div className="c-true-false-boxes" ref={el => containerRef.current[index] = el}>
              <div
                className="c-true-false-box"
                value={1}
                onClick={(el) => _handleCheckbox(index, item, el)}
              >
                <span>V</span>
              </div>
              <div
                className="c-true-false-box"
                value={0}
                onClick={(el) => _handleCheckbox(index, item, el)}
              >
                <span>F</span>
              </div>
            </div>
          </div>
        )
      })}
    </>
  );
};

export default SurveyTrueFalseItem;
