import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import routes from "../../../packs/config/routes";

// Components
import Aside from "../../components/Aside";
import BubbleBg from "../../components/BubbleBg";
import ItemImageList from "../../components/ItemImageList";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import EmailsFilter from "../../components/EmailsFilter";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import Roles from "../../config/roles";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// CustomHook
import { usePagination } from "../../hooks/usePagination";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsFromOrganization,
  fetchEmailsDestroyFromOrganization,
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const EmailsHome = (props) => {
  const [emails, setEmails] = useState([]);
  const [data, setData] = useState({});
  const [valueSearch, setValueSearch] = useState("");
  const [infoDeleteEmail, setInfoDeleteEmail] = useState({});
  const [fromFolder, setFromFolder] = useState({});
  const [loading, setLoading] = useState(true);
  const [modals, setModals] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [filterTags, setFilterTags] = useState([]);

  const emailsFiltered = () => {
    return emails.filter(
      (value) =>
        value.title.toLowerCase().indexOf(valueSearch.toLowerCase(), 0) !==
          -1 ||
        value.subject_mail
          .toLowerCase()
          .indexOf(valueSearch.toLowerCase(), 0) !== -1
    );
  };

  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePagination(emailsFiltered() ? emailsFiltered() : [], 10);
  useEffect(() => {
    props.fetchOrganizations();
    props.fetchOrganization(props.match.params.organizationId, 1, 5000);
    // getEmailsFromOrganization();
    
    if(props.match.params.folderId){
      changeFolder(props.match.params.folderId);
    }else{
      getEmailsFromOrganization()
    }
    
  }, [props.match.params.organizationId]);

  useEffect(() => {
    props.organizations.length === 0 && props.fetchOrganizations();

    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 5000);

    
    // if (props.location.state && props.location.state.folderId) {
    //   changeFolder(parseInt(props.location.state.folderId));
    // }
    // else{
    //   getEmailsFromOrganization();
    // }
  }, []);

  const getEmailsFromOrganization = () => {
    // console.log(props.match.params.folderId)
    props.fetchEmailsFromOrganization(
      props.match.params.organizationId,
      "",
      1,
      5000,
      (data) => {
        setEmails(data.custom_mails);
        setLoading(false);
        setFromFolder({});
      }
    );
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const actionModals = (type) => {
    setModals(type);
  };

  const openModalDelete = (id, idFolder) => {
    setModals("delete");
    setModalMsg("Do you want to delete this email template?");
    setInfoDeleteEmail({ id: id, idFolder: idFolder });
  };

  const successDeleteEmail = (data) => {
    setEmails(data.custom_mails);
    setModals("");
  };

  const deleteEmail = () => {
    let folderId = infoDeleteEmail.idFolder ? infoDeleteEmail.idFolder : "";
    props.fetchEmailsDestroyFromOrganization(
      props.match.params.organizationId,
      folderId,
      infoDeleteEmail.id,
      (data) => successDeleteEmail(data)
    );
  };

  const textResult = () => {
    if (emailsFiltered().length === 0 && valueSearch === "") {
      return "Currently there are no organization emails, please select a survey.";
    } else {
      return "No results";
    }
  };

  const emailsOptionsFilter = () => {
    return (
      props.organization &&
      props.organization.data &&
      props.organization.data.folders.map((org, index) => {
        return { value: org.id, text: org.name };
      })
    );
  };

  const emailsTagFilter = () => {
    let tags = [];
    tags = data && data.labels && data.labels
      .filter((tag) => tag && tag !== "")
        .map((tag, index) => {
          return { value: index, text: tag };
        });

    return tags;
  };

  const changeFolder = (folderId) => {
    let getNameFolder = "";
    if (props.organization && props.organization.data) {
      getNameFolder = props.organization.data.folders.filter(
        (folder) => folder.id === folderId
      )[0];
      if(getNameFolder){
        getNameFolder = getNameFolder.name
      }else{
        getNameFolder = ""
      }
    } else if (props.location.state && props.location.state.folderId) {
      getNameFolder = props.location.state.folderName;
      props.history.replace(window.location.pathname, null);
    }

    setFromFolder({ folderId: folderId, folderName: getNameFolder });
    props.fetchEmailsFromOrganization(
      props.match.params.organizationId,
      folderId,
      1,
      5000,
      (data) => {
        setEmails(data.custom_mails);
        setData(data)
        setLoading(false);
        reset();
      }
    );
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => actionModals("createOrganization")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals("success"),
        setModalMsg("The organization has been created successfully"),
        props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const actionModal = (type) => {
    if (type === "delete") {
      deleteEmail();
    } else if (type === "closeSession") {
      closeSession();
    } else {
      setModals("");
    }
  };

  const searchTag = () => {
    let ids = [];
    let getEmailsWithLabel = emailsFiltered().filter((email) => email.label);

    getEmailsWithLabel.map((item) => {
      item.label.map((label) => {
        if (ids) {
          ids.push(searchLabelId(item, label));
        }
      });
    });
    ids = ids.filter((id) => id);
    getEmailsWithLabel.filter((email) => ids.includes(email.id));
    return ids;
  };

  const searchLabelId = (email, label) => {
    if (filterTags.includes(label)) {
      return email.id;
    }
  };

  return (
    <motion.section
      className="o-wrapper o-wrapper--row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <BubbleBg />

      {props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => {
              setModals("closeSession"),
                setModalMsg("Are you sure you want to logout?");
            }}
          />
        )}

      <div className="o-container">
        <EmailsFilter
          changeFolder={(value) => changeFolder(value)}
          emailsFilter={emailsOptionsFilter()}
          tagsFilter={emailsTagFilter()}
          changeFolderEnabled={true}
          changeTagEnabled={true}          
          organizations={props.organizations}
          valueSearch={valueSearch}
          canManage={
            props.organization &&
            props.organization.user_role !== Roles.admin.READER
          }
          defaultOptionValue={fromFolder ? fromFolder.folderName : ""}
          getValueSearch={(e) => setValueSearch(e.currentTarget.value)}
          goToCreateEmail={() =>
            props.history.push({
              pathname: `/admin/organizations/${props.match.params.organizationId}/emails/new`,
              state: {
                fromFolder: fromFolder,
                fromOwnerableId: props.match.params.folderId,
                fromOwnerableType: props.match.params.folderId ? "Folder" : "Organization",
                ownerableTitle: "",                
                organizationName:
                  props.organization &&
                  props.organization.data &&
                  props.organization.data.name,
                organizationId: props.match.params.organizationId,
              },
            })
          }
          getEmailsOrganization={() => getEmailsFromOrganization()}
          optionFolder={
            Object.keys(fromFolder).length > 0
              ? fromFolder.folderName
              : undefined
          }
          title={
            Object.keys(fromFolder).length > 0
              ? `Survey ${data.title}`
              : "Organization emails"
          }
          goBack={() => {
            props.history.push({
              pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.match.params.folderId}`})
          }}
          getFiltersTags={(options) => setFilterTags(options)}
        />

        {emailsFiltered().filter((email) =>
          filterTags.length > 0 ? searchTag().includes(email.id) : true
        ).length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IllustrationLineSeparator />
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
              {textResult()}
            </h2>
            <IllustrationLineSeparator />
          </motion.div>
        )}
        {emailsFiltered()
          .filter((email) =>
            filterTags.length > 0 ? searchTag().includes(email.id) : true
          )
          .slice(
            paginationManage.paginationStart,
            paginationManage.paginationEnd
          )
          .map((email, index) => {
            return (
              <ItemImageList
                key={"item-email" + index}
                title={email.title}
                subtitle={email.subject_mail}
                tags={email.label && email.label}
                date={email.beautiful_day}
                typeEmail={email.type_mail}
                canManage={
                  props.organization &&
                  props.organization.user_role !== Roles.admin.READER
                }
                openModalDelete={() =>
                  openModalDelete(email.id, email.folder_id)
                }
                handleClick={() =>
                  props.history.push({
                    pathname: `/admin/organizations/${props.match.params.organizationId}/emails/show/${email.id}`,
                    state: {
                      fromFolder: fromFolder,
                      emailId: email.id,
                      optionsFilter: props.emailsOrganization.scopes_users,
                      fromOwnerableId: props.match.params.folderId,
                      fromOwnerableType: "Folder",
                      ownerableTitle: "",                      
                    },
                  })
                }
              />
            );
          })}
        {emailsFiltered() && emailsFiltered().filter((email) =>
            filterTags.length > 0 ? searchTag().includes(email.id) : true
          ).length > itemsForPage && (
          <Pagination
            marginTop={true}
            paginationManage={paginationManage}
            pagination={emailsFiltered().filter((email) =>
              filterTags.length > 0 ? searchTag().includes(email.id) : true
            )}
            goToPage={(index) => goToPage(index)}
            goToLastPage={() => goToLastPage()}
            goToFirstPage={() => goToFirstPage()}
            goToNextPage={() => goToNextPage()}
            goToPreviousPage={() => goToPreviousPage()}
            itemsForPage={itemsForPage}
            isFirst={() => checkFirst()}
            isLast={() => checkLast()}
          />
        )}
      </div>

      {["createOrganization"].includes(modals) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) => submitCreateOrganization(data)}
          type={modals}
          title={"New Organization:"}
          assessments={props.assessments}
          biodataOptions={props.organization.data.biodata_options}
        />
      )}

      {["delete", "closeSession"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={modalMsg}
          action={() => actionModal(modals)}
        />
      )}
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
      {loading && <Spinner />}
    </motion.section>
  );
};

const mapStateToProps = (state, props) => {
  return {
    organizations: getOrganizations(state, props),
    organization: getOrganization(state, props),
    emailsOrganization: state.admin.emailsOrganization,
    scopesUsers: state.admin.scopes_users,
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsFromOrganization,
  fetchEmailsDestroyFromOrganization,
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsHome);
