import React from "react";
import classNames from "classnames";

const Hamburger = (props) => {

  let classNameHamburger = classNames(
    "c-hamburger", 
    props.classNames && props.classNames
  );


  return (
    <svg
      className={classNameHamburger}
      width={props.width ? props.width : "36px"}
      height={props.height ? props.height : "26px"}
      viewBox="0 0 36 26"
    >
      <path d="M34.59375,0 L1.40625,0 C0.629578125,0 0,0.629578125 0,1.40625 C0,2.18292188 0.629578125,2.8125 1.40625,2.8125 L34.59375,2.8125 C35.3704219,2.8125 36,2.18292187 36,1.40625 C36,0.629578125 35.3704219,0 34.59375,0 Z" className="c-hamburger__top" fill="#000"></path>
      <path d="M34.59375,11.25 L1.40625,11.25 C0.629578125,11.25 0,11.8795781 0,12.65625 C0,13.4329219 0.629578125,14.0625 1.40625,14.0625 L34.59375,14.0625 C35.3704219,14.0625 36,13.4329219 36,12.65625 C36,11.8795781 35.3704219,11.25 34.59375,11.25 Z" className="c-hamburger__center"  fill="#000"></path>
      <path d="M34.59375,22.5 L1.40625,22.5 C0.629578125,22.5 0,23.1295781 0,23.90625 C0,24.6829219 0.629578125,25.3125 1.40625,25.3125 L34.59375,25.3125 C35.3704219,25.3125 36,24.6829219 36,23.90625 C36,23.1295781 35.3704219,22.5 34.59375,22.5 Z" className="c-hamburger__bottom" fill="#000"></path>
    </svg>
  );
};

export default Hamburger;



