import React, { useState, useEffect, useRef } from "react";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor';
import { Editor } from '@tinymce/tinymce-react';

// Components
import Input from "../../components/Input";

const ConsentEditor = (props) => {
  const editorRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("s-froala");

    Froalaeditor.DefineIcon('metadataIcon', {NAME: 'cog', SVG_KEY: 'tags'});
    Froalaeditor.RegisterCommand('metadata', {
        title: 'Merge Tags',
        type: 'dropdown',
        icon: "metadataIcon",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
          '${user_name}': 'User name',
          '${survey_name}': 'Survey name',
          '${organization_name}': 'Organization name'          
        },
        callback: function (cmd, val) {
          this.html.insert(val);
        }
    }); 
    return () => {
      document.body.classList.remove("s-froala");
    };
  }, []);

  const getContent = () => {
    if (editorRef.current) {
      props.getContent(editorRef.current.getContent())
    }
  };

  return (
    <div className="c-form-email">
      <form>
        <div
          key={"consentEditText"}
          className="c-form-email__input"
        >
          {
            props.title && (
              <p className="c-form-email__input-title">{ props.title }</p>
            )
          }
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            onChange={() => getContent()}
            apiKey="ettm7s8a008s2q3t32y60u1dsb0mmg9hh6g04d9q8r26w9w0"
            initialValue={props.value['body']}
            init={{
              height: 450,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
              "undo redo | formatselect | " +
                "image insertfile bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | mybutton" ,
              content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              file_browser_callback_types: 'image',
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype == 'image') {
                  var input = document.getElementById('my-file');
                  input.click();
                  input.onchange = function () {
                    var file = input.files[0];
                    var reader = new FileReader();
                    reader.onload = function (e) {
                      console.log('name',e.target.result);
                      callback(e.target.result, {
                        alt: file.name
                      });
                    };
                    reader.readAsDataURL(file);
                  };
                }
              },
              paste_data_images: true,
              setup: function (editor) {
                /* Menu items are recreated when the menu is closed and opened, so we need
                 a variable to store the toggle menu item state. */
                var toggleState = false;

                /* example, adding a toolbar menu button */
                editor.ui.registry.addMenuButton('mybutton', {
                  text: 'Tags',
                  fetch: function (callback) {
                    var items = [
                      {
                        type: 'menuitem',
                        text: 'User Name',
                        onAction: function () {
                          editor.insertContent('${user_name}');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Survey Name',
                        onAction: function () {
                          editor.insertContent('${survey_name}');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Organization Name',
                        onAction: function () {
                          editor.insertContent('${organization_name}');
                        }
                      }
                    ];
                    callback(items);
                  }
                });

              },
            }}
          />
        </div>
      </form>

      { !props.hideButton && (
        <div className="c-form-email__input-button">
          <button
            disabled={false}
            type="button"
            className={"c-button "}
            onClick={() => {
              props.updateConsent(editorRef.current.getContent())
            }}
          >
            <span className="c-button__text">Save</span>
          </button>
        </div>
      ) }

    </div>
  );
};

export default ConsentEditor;
