import React, { useState, useEffect } from "react"
import Input from "../../components/Input"

const ProcedureNewStepThree = (props) => {

  const { setEditData, editData, sendData, setStep, totalSteps } = props

  const [numLicenses, setNumLicenses] = useState(0)
  const [licensePrice, setLicensePrice] = useState(0)
  const [adminEmails, setAdminEmails] = useState([])
  const [reportEmails, setReportEmails] = useState([])

  return (
    <form style={{ maxWidth: 768 }}>
      <Input
        name="num_licenses"
        label="Número de licencias"
        errorLabel=""
        minCharacters={1}
        type="num"
        value={editData.num_licenses}
        onChange={(e) => {
          setNumLicenses(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />
      <Input
        name="price_by_license"
        label="Precio por licencia"
        errorLabel=""
        minCharacters={1}
        type="num"
        value={editData.price_by_license}
        onChange={(e) => {
          setLicensePrice(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />
      <Input
        name="email_admin"
        label="Emails de la administración"
        errorLabel=""
        minCharacters={5}
        type="text"
        value={editData.email_admin}
        onChange={(e) => {
          setAdminEmails(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />
      <Input
        name="email_reports"
        label="Emails envío de informes"
        errorLabel=""
        minCharacters={5}
        type="text"
        value={editData.email_reports}
        onChange={(e) => {
          setReportEmails(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />
      <Input
        name="reports_password"
        label="Password del informe"
        errorLabel=""
        minCharacters={5}
        type="password"
        value={editData.reports_password}
        onChange={(e) => {
          setReportEmails(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />

      <div style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: 'fit-content',
        gap: 24,
        marginInline: 'auto',
      }}>
      <button
        type="button"
        className="c-button c-button--grey c-modal__button"
        style={{ marginInline: "auto", marginTop: 40 }}
        onClick={() => setStep(2)}
      >
        <span className="c-button__text">Atrás</span>
      </button>
      <button
        type="button"
        className="c-button c-modal__button"
        style={{ marginInline: "auto", marginTop: 40 }}
        onClick={ () => totalSteps === 4 ? setStep(4) : sendData() }
      >
        <span className="c-button__text">{totalSteps === 4 ? "Continuar" : "Enviar"}</span>
      </button>
      </div>
    </form>
  )
}

export default ProcedureNewStepThree
