import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Chevron = (props) => {

  const chevronClasses = () => {
    let chevronClass = classNames(
      "c-chevron", 
      props.orientation === "up" && "c-chevron--up", 
      props.orientation === "right" && "c-chevron--right", 
      props.orientation === "left" && "c-chevron--left", 
      props.className && props.className
    );
    return chevronClass;
  }

  return (
    <svg
      onClick={() => props.action && props.action()}
      width={props.width ? props.width : "33px"}
      height={props.height ? props.height : "18px"}
      viewBox="0 0 33 18"
      className={chevronClasses()}
    >
      <g
        id="Styleguide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Illustration"
          transform="translate(-1021.000000, -569.000000)"
          fill={props.color ? props.color : "#121A22"}
        >
          <g
            id="Chevron"
            transform="translate(1037.564969, 578.317994) rotate(-180.000000) translate(-1037.564969, -578.317994) translate(1021.669969, 569.817994)"
          >
            <path
              d="M16.912295,0.20668198 C16.5141935,-0.139545095 15.6179276,-0.0507807371 14.2234972,0.472975055 L12.0803482,2.33686212 C10.3102683,4.39998501 8.45167851,6.36174919 6.50457901,8.22215467 C3.58392977,11.0127629 -0.100196196,12.9302269 0.0020834576,13.8773458 C0.104363111,14.8244648 -0.162601271,17.518764 1.51961685,16.9119344 C3.20183497,16.3051048 4.86070251,14.0062072 5.87189418,13.1267784 C6.54602196,12.5404925 7.87249566,11.0391831 9.85131528,8.62285039 C12.1973114,6.58254813 13.5718801,5.38709188 13.9750215,5.03648164 C14.3781628,4.68587141 15.2941165,3.8892706 16.7228825,2.64667922 C17.247259,1.36624147 17.3103965,0.552909056 16.912295,0.20668198 Z"
              id="Path-8"
            ></path>
            <path
              d="M14.8287488,0.255153483 C14.4790595,0.649313372 14.5687115,1.53670536 15.0977048,2.91732946 L16.9802307,5.03925918 C19.0639849,6.79181361 21.0453667,8.63200147 22.9243762,10.5598228 C25.7428905,13.4515547 27.6795291,17.0992042 28.6361193,16.9979372 C29.5927095,16.8966702 32.3139516,17.1609914 31.7010538,15.4954289 C31.0881559,13.8298664 28.7662693,12.1874233 27.8780462,11.1862434 C27.2858974,10.5187901 25.769575,9.20544984 23.3290789,7.24622249 C21.2683736,4.92345409 20.0609628,3.56249495 19.7068465,3.16334508 C19.3527301,2.76419521 18.5481633,1.85731036 17.293146,0.44269055 C15.9999039,-0.0764940509 15.1784381,-0.139006407 14.8287488,0.255153483 Z"
              id="Path-8"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

Chevron.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string,
  white: PropTypes.bool
};

export default Chevron;
