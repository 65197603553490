import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Components
import RatingStar from "../../../../components/RatingStar";
import Checkbox from "../../../../components/Checkbox";

const SurveyStarItem = (props) => {

  const [isCheckedValue, setIsCheckedValue] = useState(undefined);

  const _handleRatingCheckbox = (index, obj, selected) => {
    setIsCheckedValue(obj.value)
    return props.handleClick({ ...selected, [index]: obj });
  };

  const _handleRatingStar = (index, data, selected) => {
    return props.handleClick({ ...selected, [index]: data });
  };

  const _isChecked = (obj, selected) => {

    if(isCheckedValue != undefined){
      return ""+obj.value == ""+isCheckedValue
    }
    else{
      if(Object.keys(props.selectedItems).length > 0 && props.selectedItems[0] !== undefined){
        return ""+props.selectedItems[0].value == ""+obj.value
      }
    }

    return false 
  }

  const getTextSide = (itemData, agree) => {
    if(itemData["items"] && itemData["items"]["0"]){
      let keys = Object.keys(itemData["items"])
      if(agree){
        return itemData["items"][keys[0]]["text"]
      }else{
        return itemData["items"][keys[keys.length - 1]]["text"]
      }
    }else{
      if(agree){
        return props.language === "en" ? "Totally agree" : props.language === "pt" ? "Concordo totalmente" : "Totalmente de acuerdo"
      }else{
        return props.language === "en" ? "Totally disagree" : props.language === "pt" ? "Discordo totalmente" : "Totalmente en desacuerdo"  
      }
      
    }
    
  }
  const _starsInputs = (index, itemData) => {

    let ratingSize = Object.keys(props.questionData.items[0].items).length;

    const answerClasses = classNames(
      "c-answer",
      (props.questionData.item_format_style === 5 || props.questionData.item_format_style === 7  || props.questionData.item_format_style === 8) &&
        "o-display--flex o-display--flex-h-center"
       
    );
    
    return (
      <div className={answerClasses} key={`answer-${index}`}>
        {
          props.labeled == true &&
          <h6 className="c-rating-checkbox__text c-rating-checkbox__text--left">{getTextSide(itemData, false)}</h6>
        }
        {
          !props.checkboxMode && (
            <RatingStar
              key={index}
              values={itemData.items}
              currentValue={props.selectedItems[index] || {}}
              onClick={(e, i, val) =>
                  _handleRatingStar(index, val, props.selectedItems)
              }
              small={ratingSize > 7}
            />
          )
        }
        {
          props.labeled == true &&
           <h6 className="c-rating-checkbox__text c-rating-checkbox__text--right">{getTextSide(itemData, true)}</h6>
        }
    
        {
          props.checkboxMode && (
            <div className="c-rating-checkbox__wrapper">
              <h6 className="c-rating-checkbox__text c-rating-checkbox__text--left">Totalmente en desacuerdo</h6>
              {
                Object.entries(itemData.items).map((obj, index) => {
                  return (
                    <div key={index} className="c-rating-checkbox">
                      <p className="c-rating-checkbox__label">{index + 1 }</p>
                      <Checkbox 
                        tickClassName="o-margin--right-zero"
                        checked={_isChecked(obj[1], props.selectedItems)}
                        value={index + 1}
                        onClick={(text, checked, value) => 
                            _handleRatingCheckbox(0, obj[1], props.selectedItems)
                        }
                      />
                    </div>
                  )
                })
              }
              <h6 className="c-rating-checkbox__text c-rating-checkbox__text--right">Totalmente de acuerdo</h6>
            </div>
          )
        }
      </div>
    );
  };

  return Object.entries(props.questionData.items).map((arr) => {
    return _starsInputs(arr[0], arr[1]);
  });
};

SurveyStarItem.propTypes = {
  questionData: PropTypes.object,
  selectedItems: PropTypes.object,
  renderSelect: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default SurveyStarItem;
