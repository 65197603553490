import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

// Components
import Checkbox from "../../../components/Checkbox";
import Pagination from "../../../components/Pagination";
import Eye from "../../../components/Eye";
import Export from "../../../components/Export";
import Envelope from "../../../components/Envelope";
import Close from "../../../components/Close";
import Chevron from "../../../components/Chevron";
import IllustrationMonitor from "../../../components/IllustrationMonitor";
import Multiselect from "../../../components/Multiselect";
import ProgressBar from "../../../components/ProgressBar";

// CustomHook
import { usePagination } from "../../../hooks/usePagination";
import IllustrationWebcam from "../../../components/IllustrationWebcam";

const ProcedureTableResults = (props) => {
  const [userList, setUserList] = useState([]);
  const [typeOrder, setTypeOrder] = useState("");
  const [modeOrder, setModeOrder] = useState("asc");

  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePagination(props.data ? props.data : [], 20);

  useEffect(() => {
    setUserList(props.data);
  }, [props.data]);



  const orderItems = (type, mode) => {
    reset();
    let _userList = JSON.parse(JSON.stringify(userList));
    let orderList = [];

    orderList = _userList.sort((a, b) =>
      a[type] > b[type] ? 1 : b[type] > a[type] ? -1 : 0
    );

    if (mode === "desc") {
      orderList = _userList.reverse();
    }

    setTypeOrder(type);
    setModeOrder(mode);
    setUserList(orderList);
  };
  
  const showReport = (url) => {
    window.open(url, "_blank");
  };


  return (
    <React.Fragment>
      <div
        className="c-table-report o-grid o-grid--five-special o-margin--bottom-size-giga"
      >
        <div className="c-table-report__column">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
          >
         
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Name
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
              onClick={() => orderItems("name", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "email" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("name", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "name" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </motion.div>
          {userList
            .slice(
              paginationManage.paginationStart,
              paginationManage.paginationEnd
            )
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-email-" + item.title}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                  data-position={index}
                >
                  <p class="c-table-report__cell-text c-table-report__cell-text--bold">{item.title}</p>                
                </motion.div>
              );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Date
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "date" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "date" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>

          {userList
            .slice(
              paginationManage.paginationStart,
              paginationManage.paginationEnd
            )
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-date-" + item.index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align  c-table-report__cell--vertical-align-center"
                  data-position={index}
                >
                  <p className="c-table-report__cell__item">{item.date}</p>
                </motion.div>
              );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Status
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("status", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "status" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("status", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "status" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>
            {userList
              .slice(
                paginationManage.paginationStart,
                paginationManage.paginationEnd
              )
              .map((item, index) => {
                if (
                  item.status === 'completed'
                ) {
                  return (
                    <motion.div
                      key={"table-report-progress-" + item.email}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                    >
                      <ProgressBar
                        noTitle={true}
                        progress={
                          item.progress === "" ? 0 : parseInt(item.progress)
                        }
                        contentClasses='o-margin--bottom-zero'
                        admin={true}
                      />
                      {
                        item.links.length > 0 &&
                          <Multiselect
                          defaultValue="Informe"
                          multiselectClassName="c-multiselect--white-bg c-multiselect--border"
                          multiselectWrapperClassName='o-display--flex o-display--flex-v-center o-margin--bottom-zero'
                          multiple={false}
                          options={item.links}
                          getOption={(value) => {
                            showReport(value);
                          }}
                        />
                      }
                      
                    </motion.div>
                  );
                }
              })
            }
        </div>
         <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center ">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Score
            </p>
          </div>
          {userList
            .slice(
              paginationManage.paginationStart,
              paginationManage.paginationEnd
            )
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-date-" + item.index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align  c-table-report__cell--vertical-align-center"
                  data-position={index}
                >
                  <p className="c-table-report__cell__item">{item.score || "-"}</p>
                </motion.div>
              );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center ">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Actions
            </p>
          </div>

          {userList
            .slice(
              paginationManage.paginationStart,
              paginationManage.paginationEnd
            )
            .map((item, index) => {

              let videos = item.videos
              let webcam_video = []
              let screen_recording = []
              if(videos.length > 0){
                webcam_video = videos.filter(e => e.type == "webcam")[0]
                if(webcam_video === undefined) webcam_video = [] 
                screen_recording = videos.filter(e => e.type == "screen_recording")[0]
                if(screen_recording === undefined) screen_recording = [] 
              }
              return (
                <motion.div
                  key={"table-report-date-" + index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                  data-position={index}
                >
                  <div className="c-table-report__cell-icons">
                    <div
                      className="c-table-report__cell-icons-item"
                      onClick={() => {props.getScoreUserFolder(item.id, item.procedure_id)}}
                    >
                      <Export width={18} height={24}/>
                    </div>
                    <div
                      className="c-table-report__cell-icons-item"
                      onClick={() => webcam_video.length == 0 ? "" : props.openVideoModal(webcam_video)}
style={webcam_video.length == 0 ? {pointerEvents: 'none'} : {}}
                    >
                      <IllustrationMonitor style={webcam_video.length == 0 ? {opacity: 0.4} : {}} width={23} height={24}/>
                    </div>
                    <div
                      className="c-table-report__cell-icons-item"
                      onClick={() => screen_recording.length == 0 ? "" : props.openVideoModal(screen_recording)}
style={screen_recording.length == 0 ? {pointerEvents: 'none'} : {}}
                    >
                      <IllustrationWebcam style={screen_recording.length == 0 ? {opacity: 0.4} : {}} width={18} height={24}/>
                    </div>
                  </div>
                </motion.div>
              );
            })}
        </div>
      </div>
      {userList && userList.length > itemsForPage && (
        <Pagination
          paginationManage={paginationManage}
          pagination={userList}
          goToPage={(index) => goToPage(index)}
          goToLastPage={() => goToLastPage()}
          goToFirstPage={() => goToFirstPage()}
          goToNextPage={() => goToNextPage()}
          goToPreviousPage={() => goToPreviousPage()}
          itemsForPage={itemsForPage}
          isFirst={() => checkFirst()}
          isLast={() => checkLast()}
        />
      )}
    </React.Fragment>
  )
};

export default ProcedureTableResults;
