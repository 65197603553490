// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import { connect } from "react-redux"

// Components
import BubbleBg from "../../components/BubbleBg";
import Dialog from "../../components/Dialog";
import Tick from "../../components/Tick";
import IllustrationHandConfirmation from "../../components/IllustrationHandConfirmation";
import IllustrationMonitor from "../../components/IllustrationMonitor";
import NoMobileView from "../NoMobileView";

// Proctoring Screens
import Titles from "./Titles";
import CameraSpeakers from "./CameraSpeakers";
import ScreenRecorder from "./ScreenRecorder";
import Success from "./Success";
import Permission from "./Permission";

// Actions
import { redirectTo } from "../../actions";

// Config
import routes from "../../../packs/config/routes";

// Hooks
import useViewportDetect from "../../hooks/useViewportDetect";

import internalization from "../../../packs/config/localize"

const Proctoring = (props) => {
  const [isVisible, setIsVisible] = useState("");
  const [step, setStep] = useState(props.default_step || 0);
  const [viewport, setViewport] = useState({});

  // Hooks
  const viewportDectect = useViewportDetect();

  // Refs
  const wrapper = useRef(null);
  const bubble = useRef(null);

  const content = [
    <Titles internalization={internalization} title={props.title_title} description={props.title_description} buttonAction={()=> setStep(step + 1)}/>,
    <CameraSpeakers 
      title={internalization.proctoring.camera_speakers.title}
      description={props.camera_speaker_description ? props.camera_speaker_description : internalization.proctoring.camera_speakers.description}
      buttonAction={()=> setStep(step + 1)}
      onPermissionDenied={() => props.onPermissionDenied ? props.onPermissionDenied() : ""}
      internalization={internalization}
      trackError={data => trackError(data)}
    />
    ,
    <ScreenRecorder 
      title={internalization.proctoring.screen_recording.title}
      description={props.screen_recorder_description ? props.screen_recorder_description :  internalization.proctoring.screen_recording.description}
      buttonAction={()=> setStep(step + 1)}
      onPermissionDenied={() => props.onPermissionDenied ? props.onPermissionDenied() : ""}
      internalization={internalization}
      trackError={data => trackError(data)}
    />,
    <Success 
      title={internalization.proctoring.success.title}
      description={props.success_description ? props.success_description : internalization.proctoring.success.description}
      button_text={props.success_button ? props.success_button : internalization.buttons.continue}
      buttonAction={()=> props.onSuccess ? props.onSuccess() : props.redirectTo(routes.client.home)}
      internalization={internalization}
    />,
    <Permission 
      title={internalization.proctoring.permissions.title}
      description={internalization.proctoring.permissions.description}
      buttonAction={()=> location.reload()}
      internalization={internalization}
      trackError={data => trackError(data)}      
    />
  ];

  const renderContent = () => {
    let screenStep = (
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        key={`0-${step}`}
      >
        {content[step]}
      </motion.div>
    );

    return screenStep;
  }

  useEffect(()=> {
    setTimeout(()=> {
      if(props.onSurvey) {
        let containerParent = wrapper.current.closest(".o-container");
        let container = wrapper.current.querySelector(".js-proctoring-container");
        containerParent.style.padding =  "0";
        container.style.maxWidth = "100%"
      }
    }, 50)
  }, [renderContent()])

  useEffect(()=> {
    if(viewportDectect) {
      setViewport(viewportDectect);
    } 
  }, [viewportDectect])

  useEffect(()=> {
    let menu = document.querySelector(".c-menu");
    let footer = document.querySelector(".c-footer");
    if(viewport.isMobile) {
      menu && menu.classList.add("u-hide");
      footer && footer.classList.add("u-hide");
    } else {
      menu && menu.classList.remove("u-hide");
      footer && footer.classList.remove("u-hide");
    }
  }, [viewport])

  useEffect(()=> {
    if(!props.onSurvey){
      var languageUserAgent = /^es/.test(navigator.language || navigator.userLanguage || "en") ? "es" : "en";
      internalization.setLanguage(languageUserAgent)      
    }
  }, [props.onSurvey])
  

  const trackError = async (data) => {
    const response = await fetch('/api/tracking_events', {
      method: 'POST',
      body: JSON.stringify({"tracking_event": data }),     
      headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'Authorization': props.token
      }
    });
    const myJson = await response.json(); //extract JSON from the http response
    // do something with myJson
  }

  return (
    <React.Fragment>
      {
        viewport.isMobile && (
          <AnimatePresence exitBeforeEnter>
            <NoMobileView  />
          </AnimatePresence>
        )
      }
      {
        !viewport.isMobile && (
          <motion.section
            className="o-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            ref={wrapper}
          >
            {!props.onSurvey && <BubbleBg />}

            <AnimatePresence exitBeforeEnter>
              { renderContent() }
            </AnimatePresence>
          </motion.section>
        )
      }
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  redirectTo,
};

export default connect(null, mapDispatchToProps)(Proctoring);

