// Dependencies
import React from "react";
import PropTypes from "prop-types";

const Table = (props) => {
  return (
    <table className="c-table-data">
      <thead className="c-table-data__header">
        <tr className="c-table-data__row">
          <th
            colSpan="2"
            className="c-table-data__cell c-table-data__cell--border-radius c-table-data__cell--bg-dark c-table-data__cell--border-none"
          >
            <h5 className="c-table-data__title c-table-data__title--white">
              Información sobre protección de datos
            </h5>
          </th>
        </tr>
      </thead>
      <tbody className="c-table-data__body">
        <tr className="c-table-data__row">
          <td className="c-table-data__cell c-table-data__cell--width-thirty">
            <strong>Responsable del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text c-table-data__text--bottom-ten">Facthum Spain, S.L. (FACTHUM)</p>
            <p className="c-table-data__text c-table-data__text--bottom-ten">
              Domicilio Social: Carretera de la Coruña km 23,200, Edificio Las Rozas 23, Planta 1, Oficina 3, 28290 Las Rozas (Madrid)
            </p>
            <p className="c-table-data__text c-table-data__text--bottom-ten">CIF: B85643328</p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Finalidades del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
             Sus datos se tratan en el contexto del proceso de evaluación del que usted forma parte y al que ha sido invitado por parte de su empresa. Los datos evaluados pueden ser rasgos de personalidad, competencias, aptitudes, nivel de inglés, etc. El objetivo de la evaluación es facilitar la toma de decisiones por parte de la empresa que le ha invitado.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Legitimación del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
             Sus datos personales serán tratados por el consentimiento expresamente otorgado por usted.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Conservación de los datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
              Los criterios que FACTHUM utiliza para fijar los plazos de conservación de sus datos han sido determinados de acuerdo con los requisitos establecidos en la legislación, reglamentos y directrices normativas aplicables, así como los requisitos operacionales de FACTHUM relacionados con las necesidades de la empresa que le ha invitado.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Comunicación de los datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
             Sus datos personales pueden ser cedidos por FACTHUM a la empresa que ha solicitado su evaluación, cuando así se requiera para las finalidades del tratamiento indicadas anteriormente.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Transferencia Internacional de datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">No se realiza Transferencia Internacional de Datos.</p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Derechos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text c-table-data__text--bottom-ten">
              El consentimiento prestado en el supuesto previsto podrá ser revocado por usted en cualquier momento. Le informamos que de acuerdo con la legislación vigente usted tiene derecho a ejercer sus derechos de acceso, rectificación, oposición, derecho de supresión, de limitación del tratamiento y el derecho a la portabilidad de sus datos, derecho información y derecho a no ser objeto de decisiones individual automatizadas, acreditando su identidad (mediante copia de DNI o equivalente) en la siguiente dirección:  Facthum Spain, Carretera de la Coruña km 23,200, Edificio Las Rozas 23, Planta 1, Oficina 3, 28290 Las Rozas de Madrid (España), o al siguiente email: info@facthum.com,  acreditando su identidad (mediante copia de DNI o equivalente).
            </p>
            <p className="c-table-data__text">
              En caso de que no se haya satisfecho el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad de Control. Obtenga más información en www.agpd.es.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Como hemos obtenido sus datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
              Los datos necesarios para el envío de esta evaluación (nombre y correo electrónico) han sido cedidos a FACTHUM por la empresa que solicita su evaluación.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}


// static propTypes = {
//   isVisible: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired,
// };

export default Table;
