// Dependencies
import React from "react";
import classNames from "classnames";

const ArrowDouble = (props) => {
  const arrowDoubleClasses = () => {
    let arowDoubleClass = classNames(
      "c-arrow-double",
      props.className && props.className
    );
    return arowDoubleClass;
  };
  return (
    <svg
      width="22px"
      height="34px"
      viewBox="0 0 22 34"
      className={arrowDoubleClasses()}
    >
      <g
        id="Styleguide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Illustration"
          transform="translate(-295.000000, -1208.000000)"
          fill="#121820"
        >
          <g id="arrow_double" transform="translate(295.000000, 1208.000000)">
            <g id="arrow_down">
              <path
                d="M8.88284232,5.82573545 C8.73284232,5.85269841 8.5180275,5.88543915 8.26247195,5.9258836 L8.26247195,5.9258836 C6.93839787,6.14351323 4.48284232,6.62884656 3.74024973,7.7535873 L3.74024973,7.7535873 C3.14765713,8.64914286 2.72173121,9.29817989 2.46247195,9.70069841 L2.46247195,9.70069841 L2.0106201,11.8326984 L3.27543491,11.8808466 C4.56247195,9.03047619 6.00136084,7.59180952 7.59395343,7.56484656 L7.59395343,7.56484656 C9.98284232,7.52247619 10.4272868,7.10455026 12.1124719,7.23166138 L12.1124719,7.23166138 C13.2346942,7.31447619 14.2180275,7.61106878 15.0624719,8.12336508 L15.0624719,8.12336508 C17.0106201,9.92217989 19.0346942,12.3873651 19.2050645,13.4986243 L19.2050645,13.4986243 C19.3735831,14.6079577 20.1217312,14.9931429 21.449509,14.6541799 L21.449509,14.6541799 C22.1550645,14.4577354 22.0235831,13.5929947 21.0550645,12.0580317 L21.0550645,12.0580317 C19.599509,9.75462434 17.2291386,7.47625397 16.2235831,6.89269841 L16.2235831,6.89269841 C14.0680275,5.81417989 12.3976571,5.53684656 11.1624719,5.53684656 L11.1624719,5.53684656 C10.0680275,5.53684656 9.3180275,5.75447619 8.88284232,5.82573545 L8.88284232,5.82573545 Z"
                id="Clip-2"
              ></path>
              <path
                d="M5.89254602,0.123809524 C5.11387936,0.72 4.57343491,2.38857143 4.17521269,3.07238095 L4.17521269,3.07238095 C3.9103238,3.52952381 3.43387936,4.64761905 2.74765713,6.43047619 L2.74765713,6.43047619 C1.82321269,8.01714286 1.28099047,8.95047619 1.12276824,9.22285714 L1.12276824,9.22285714 C0.964546022,9.49714286 0.601879356,10.1180952 0.0383238,11.0838095 L0.0383238,11.0838095 C-0.0558984222,11.9638095 0.0241015778,12.487619 0.272990467,12.6533333 L0.272990467,12.6533333 C0.523657133,12.8228571 0.971657133,12.6533333 1.61521269,12.1447619 L1.61521269,12.1447619 L2.46143491,10.6895238 C3.08721269,9.16 3.77343491,7.68380952 4.5183238,6.25714286 L4.5183238,6.25714286 C5.63654602,4.12 7.26321269,2.43809524 7.08187936,1.85142857 L7.08187936,1.85142857 C6.93610158,1.37714286 6.7583238,0.146666667 6.28365713,0.0114285714 L6.28365713,0.0114285714 C6.25521269,0.00571428571 6.22499047,-1.59872116e-14 6.19654602,-1.59872116e-14 L6.19654602,-1.59872116e-14 C6.10587936,-1.59872116e-14 6.00454602,0.0380952381 5.89254602,0.123809524 L5.89254602,0.123809524 Z"
                id="Clip-5"
              ></path>
              <path
                d="M0.1503238,10.3945651 C0.00987935559,10.7230984 0.0738793556,11.253054 0.338768244,11.9805206 L0.338768244,11.9805206 L1.21165713,12.8526984 C2.16099047,13.4491429 3.06765713,14.1198984 3.93343491,14.8727873 L3.93343491,14.8727873 C5.23299047,15.9991873 6.16987936,17.8041651 6.5823238,17.5166984 L6.5823238,17.5166984 C6.99299047,17.2253206 8.17699047,16.7481651 7.86587936,15.8603429 L7.86587936,15.8603429 C7.55654602,14.9725206 6.50587936,14.5031873 6.09343491,14.0905651 L6.09343491,14.0905651 C5.81965713,13.8167873 5.12810158,13.3611429 4.01876824,12.7216762 L4.01876824,12.7216762 C3.06410158,11.7693206 2.50410158,11.2100317 2.34054602,11.0457651 L2.34054602,11.0457651 C2.17521269,10.883454 1.80187936,10.5118984 1.22054602,9.93109841 L1.22054602,9.93109841 C1.19210158,9.93109841 1.16187936,9.92914286 1.13343491,9.92914286 L1.13343491,9.92914286 C0.612546022,9.92914286 0.283657133,10.0855873 0.1503238,10.3945651 L0.1503238,10.3945651 Z"
                id="Clip-8"
              ></path>
            </g>
            <g id="arrow_up" transform="translate(0.000000, 16.000000)">
              <path
                d="M0.445906815,2.8898963 C-0.259648741,3.08441481 -0.128167259,3.94915556 0.842203111,5.48797037 L0.842203111,5.48797037 C2.29405496,7.79137778 4.66812904,10.0678222 5.67368459,10.6513778 L5.67368459,10.6513778 C9.73849941,12.6851556 12.0866476,11.868563 13.0107216,11.7183407 L13.0107216,11.7183407 C13.1625735,11.6913778 13.3755364,11.660563 13.6347957,11.6181926 L13.6347957,11.6181926 C14.9570179,11.400563 17.4125735,10.9152296 18.1570179,9.79048889 L18.1570179,9.79048889 C18.7477587,8.89685926 19.1755364,8.2458963 19.431092,7.84337778 L19.431092,7.84337778 L19.8847957,5.7133037 L18.6218327,5.66515556 C17.3347957,8.5136 15.894055,9.95226667 14.3014624,9.98115556 L14.3014624,9.98115556 C11.9107216,10.0216 11.468129,10.4395259 9.78294385,10.3124148 L9.78294385,10.3124148 C8.65886978,10.2276741 7.67553644,9.93300741 6.8347957,9.42263704 L6.8347957,9.42263704 C4.8847957,7.6218963 2.85886978,5.15863704 2.69220311,4.04737778 L2.69220311,4.04737778 C2.56072163,3.19611852 2.09220311,2.77048889 1.281092,2.77048889 L1.281092,2.77048889 C1.0347957,2.77048889 0.757017926,2.80900741 0.445906815,2.8898963 L0.445906815,2.8898963 Z"
                id="Clip-11"
              ></path>
              <path
                d="M20.2784253,5.40121905 L19.4339809,6.85264762 C18.8064253,8.38407619 18.1202031,9.86217143 17.377092,11.2888381 L17.377092,11.2888381 C16.2588698,13.425981 14.6304253,15.1078857 14.8117587,15.6926476 L14.8117587,15.6926476 C14.9593142,16.1669333 15.137092,17.3955048 15.6117587,17.5307429 L15.6117587,17.5307429 C15.7237587,17.5650286 15.8535364,17.5364571 16.0046476,17.4202667 L16.0046476,17.4202667 C16.7833142,16.8240762 17.3202031,15.1574095 17.7184253,14.4736 L17.7184253,14.4736 C17.985092,14.0145524 18.4615364,12.8964571 19.1459809,11.1155048 L19.1459809,11.1155048 C20.0704253,9.52502857 20.6144253,8.59550476 20.7726476,8.32121905 L20.7726476,8.32121905 C20.9326476,8.0488381 21.2935364,7.42598095 21.857092,6.4583619 L21.857092,6.4583619 C21.9513142,5.58217143 21.873092,5.0583619 21.6224253,4.89074286 L21.6224253,4.89074286 C21.5602031,4.8488381 21.4837587,4.82788571 21.3984253,4.82788571 L21.3984253,4.82788571 C21.1353142,4.82788571 20.7619809,5.01645714 20.2784253,5.40121905 L20.2784253,5.40121905 Z"
                id="Clip-14"
              ></path>
              <path
                d="M15.313092,0.0293333333 C14.9024253,0.318755556 13.7184253,0.795911111 14.0277587,1.68373333 L14.0277587,1.68373333 C14.3406476,2.57155556 15.3895364,3.04284444 15.8002031,3.45155556 L15.8002031,3.45155556 C16.0757587,3.72728889 16.7673142,4.18488889 17.8766476,4.8224 L17.8766476,4.8224 C18.8313142,5.77475556 19.3913142,6.33404444 19.5566476,6.49831111 L19.5566476,6.49831111 C19.7184253,6.66062222 20.0935364,7.03413333 20.6748698,7.61297778 L20.6748698,7.61297778 C21.249092,7.63057778 21.6064253,7.47608889 21.7468698,7.14951111 L21.7468698,7.14951111 C21.8855364,6.82097778 21.8233142,6.29297778 21.5566476,5.56355556 L21.5566476,5.56355556 L20.6819809,4.69137778 C19.7344253,4.09688889 18.8259809,3.42417778 17.9602031,2.67128889 L17.9602031,2.67128889 C16.7566476,1.62506667 15.8624253,3.5971226e-14 15.4108698,3.5971226e-14 L15.4108698,3.5971226e-14 C15.3753142,3.5971226e-14 15.3433142,0.00782222222 15.313092,0.0293333333 L15.313092,0.0293333333 Z"
                id="Clip-17"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDouble;
