import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Actions
import { fetchPassword } from "./actions";
import { redirectTo } from "../../actions";

// Components
import Input from "../../components/Input";
import Button from "../../components/Button";
import BubbleBg from "../../components/BubbleBg";
import IllustrationHand from "../../components/IllustrationHand";
import Modal from "../../components/Modal";
import routes from "../../config/routes";

import internalization from "../../config/localize"


class RescuePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: null,
      },
      showModal: false,
      modalData: {title: "", action: ""},
      emailError: null,
      isSubmitButtonDisabled: true,
    };
  }

  _setValues = (value, type, state) => {
    this.setState({
      data: { ...state.data, [type]: value },
    });
  };

  _submit = (data) => {
    this.props.fetchPassword(data, () => this._success(), () => this._error());
  };

  _success(){
    this.setState({showModal: true, modalData: {title: internalization.signin.email_sent, action: () => this.props.redirectTo(routes.client.signin)}})
  }

  _error(){
    this.setState({showModal: true, modalData: {title: internalization.signin.email_not_found, action: () => this.setState({showModal: false})}})

  }

  _validateEmail = (email, state) => {
    const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!emailPattern.test(email)) {
      this.setState({
        emailError: internalization.form.email_not_valid,
        isSubmitButtonDisabled: true,
      });
    } else {
      this.setState({
        emailError: null,
        isSubmitButtonDisabled: false,
      });
    }
  };

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <BubbleBg />
        <div className="o-container o-display--flex@lg-and-up o-display--flex-space-between@md-only o-display--flex-row-reverse@lg-and-up">
          <div className="o-col--1/2@lg">
            <h1 className="o-margin--bottom-size-l">{internalization.signin.rescue_password}</h1>
            <form>
              <Input
                className="c-input--white-bg"
                label="Email"
                errorLabel={this.state.emailError}
                showError={true}
                isRequired={true}
                minCharacters={5}
                type="text"
                value={this.state.email}
                onChange={(e) =>{
                    this._setValues(e.currentTarget.value, "email", this.state)
                    this._validateEmail(e.currentTarget.value, this.state)
                  }
                }
              />
              <div className="c-button__wrapper">
                <Button
                  name="login"
                  type="submit"
                  text={internalization.buttons.send}
                  disabled={this.state.isSubmitButtonDisabled}
                  onClick={() => this._submit(this.state.data)}
                />
              </div>
            </form>
          </div>
          <div className="o-col--1/2@lg o-display--flex o-display--flex-h-right@md-only u-hide@sm-and-down o-margin--top-size-xxl@md-only">
            <IllustrationHand />
          </div>
        </div>
        <Modal isVisible={this.state.showModal}>
          <p>{this.state.modalData.title}</p>
          <div className="c-modal__buttons-wrapper">
            <button 
              type="button"
              className="c-button c-modal__button"
              onClick={() =>
                {this.state.modalData.action()}
              }
            >
              <span
                className="c-button__text"
              >
                {internalization.buttons.accept}
              </span>
            </button>
          </div>
        </Modal>
      </motion.section>
    );
  }

  static propTypes = {};
}

const mapDispatchToProps = {
  fetchPassword,
  redirectTo,
};

export default connect(null, mapDispatchToProps)(RescuePassword);
