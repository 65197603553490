import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Tick = (props) =>  {

  const [tickClasses, setTickClasses] = useState("c-tick")
  const [tickIllustrationClasses, setTickIllustrationClasses] = useState("c-tick__illustration")
  const [groupClasses, setGroupClasses] = useState("")

  // Handle classes
  useEffect(()=> {

    setTickClasses(classNames(
      "c-tick", 
      props.medium && "c-tick--medium", 
      props.big && "c-tick--big", 
      props.background && "c-tick--bg", 
      props.className
    ))

    setTickIllustrationClasses(classNames(
      "c-tick__illustration", 
      props.illustrationClassName
    ))

    setGroupClasses(classNames(props.illustrationClassName))
  }, [props])

  return (
    <div className={tickClasses}>
      <svg
        width="21px"
        height="19px"
        viewBox="0 0 21 19"
        className={tickIllustrationClasses}
        style={props.style}
      >
        <g
          id="Disclaimer"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="01_Desktop"
            transform="translate(-591.000000, -605.000000)"
            fill={props.color ? props.color : "#76b0eb"}
            className={groupClasses}
          >
            <g id="Group" transform="translate(399.000000, 326.000000)">
              <g id="OK" transform="translate(190.000000, 278.000000)">
                <g id="Tick" transform="translate(2.606951, 0.842404)">
                  <path
                    d="M8.21369487,20.4261689 C7.4617287,19.8685345 6.96237362,19.652868 6.68137852,19.2706691 C5.16003381,17.2073684 3.6925705,15.1038707 2.21376381,13.0089867 C2.07752077,12.8164526 1.92424068,12.5836923 1.92131368,12.3653581 C1.90973512,11.8051569 1.97758406,11.2421156 2.01139213,10.6790603 C2.50222156,10.8573769 3.00439082,11.0184613 3.48388636,11.228374 C3.64277224,11.2973869 3.77900933,11.475557 3.87268822,11.6364726 C5.20690319,13.9295202 6.54962601,16.2168258 7.83562711,18.5357087 C8.06273605,18.9466132 8.03743463,19.5010537 8.21369487,20.4261689 Z"
                    id="Path"
                    transform="translate(5.066847, 15.552615) rotate(152.000000) translate(-5.066847, -15.552615) "
                  ></path>
                  <path
                    d="M8.81824717,6.72 C12.9299884,7.18845819 17.1343442,7.23341746 20.9218761,9.30174768 C21.6147184,9.67780563 22.2613588,10.0538866 22.861727,10.5711415 C23.4159166,11.041369 24.2934732,11.6055364 23.8772343,12.4526491 C23.460972,13.3468121 22.4909413,12.9238417 21.7980053,12.7359851 C16.8550031,11.5151353 11.7729035,11.2824113 6.78348863,10.4850374 C6.64490612,10.438056 6.46007485,10.4851982 6.32149234,10.4382168 C5.16663031,10.0623887 3.5497252,9.73384061 3.55038017,8.41643141 C3.55110573,6.95787121 5.26041479,7.2863733 6.32317707,7.05059315 C7.1549061,6.86197822 7.98656494,6.81451427 8.81824717,6.72 Z"
                    id="Path"
                    transform="translate(13.768032, 9.860858) scale(-1, 1) rotate(-133.000000) translate(-13.768032, -9.860858) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
Tick.propTypes = {
  className: PropTypes.string,
  groupClass: PropTypes.string,
};

export default Tick;
