// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Download = (props) => {

  let classNameDownload = classNames(
    "c-download", 
    props.className && props.className
  );

  return (
    <svg
      className={classNameDownload}
      width={props.width ? props.width : "22px"}
      height={props.height ? props.height : "26px"}
      viewBox="0 0 22 26"
    >
      <title>download</title>
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustration" transform="translate(-96.000000, -1225.000000)" fill={props.color ? props.color : "#121921"} className="filled">
          <g id="download" transform="translate(96.000000, 1225.000000)">
            <g id="Group-6" transform="translate(0.044421, 0.037874)">
              <path d="M19.9058783,13 C19.5163766,13 18.7062677,13.583814 18.7062677,14.4275615 C19.1961077,17.8449169 18.9332537,19.8945116 17.9177055,20.5763455 C16.3945427,21.5991761 16.6201108,21.9110963 14.1124696,22.3406246 C11.6047487,22.7701528 8.55513928,22.4438007 6.55588346,22.3406246 C4.55662765,22.2373688 4.07912292,21.9080664 2.55572093,21.9080664 L2.84579402,21.9080664 C1.86865116,21.9080664 1.93891642,19.1844252 1.49033171,17.6211561 C0.496777236,16.8577807 0,16.8577807 0,17.6211561 C0,18.766299 1.17360797,22.2113754 1.31760797,23.1868439 C1.40699003,23.7929037 1.9591495,23.7036013 2.84579402,23.614299 C3.38647176,23.5598405 4.05153488,23.5053821 4.81203987,23.6084784 C6.15045847,23.7900332 7.38251163,23.9746977 8.50827907,24.162392 C10.2347641,24.1428571 14.841958,23.9582724 15.9831141,23.6084784 C17.6950078,23.0837475 18.3074464,22.3406246 19.0365361,21.9080664 C19.7657055,21.4754286 19.6858915,20.2869103 19.8958317,18.8709103 C20.1056922,17.4549103 20.3109281,15.9077475 20.3582902,14.4275615 C20.4964696,13.4119867 20.2331075,13 19.905958,13 L19.5756896,13 C19.5756364,13 19.6856993,13 19.9058783,13 Z" id="Clip-11"></path>
              <g id="arrow">
                <path d="M18.9659715,6.24661794 C17.973121,6.6151495 16.9543568,8.10689701 16.3455861,8.66990033 L16.3455861,8.66990033 C15.9397389,9.04528904 15.1329083,10.0145382 13.9253336,11.5776478 L13.9253336,11.5776478 C12.5129216,12.8838538 11.6852804,13.6492226 11.4425695,13.8736744 L11.4425695,13.8736744 C11.1997788,14.0981262 10.6483369,14.6081063 9.78816415,15.4036944 L9.78816415,15.4036944 C9.45312096,16.2425781 9.39746647,16.7810233 9.62104122,17.0186312 L9.62104122,17.0186312 C9.84461597,17.2563987 10.3680711,17.2156545 11.1916459,16.8963189 L11.1916459,16.8963189 L12.4819847,15.7030166 C13.5599117,14.3699402 14.6869548,13.1059934 15.863194,11.9110963 L15.863194,11.9110963 C17.6274731,10.1185914 19.8156193,8.92090365 19.7781442,8.29044518 L19.7781442,8.29044518 C19.7442572,7.71827243 19.920709,6.19710299 19.2109947,6.19686379 L19.2109947,6.19686379 C19.1387555,6.19686379 19.0575861,6.21257143 18.9659715,6.24661794 L18.9659715,6.24661794 Z" id="Clip-2"></path>
                <path d="M2.09317411,6.99037874 C1.98338009,6.99683721 1.83858275,6.9956412 1.67640335,6.99444518 L1.67640335,6.99444518 C0.90744654,6.98870432 -0.254599972,6.98296346 0.0496658088,7.79497674 L0.0496658088,7.79497674 C0.418117636,8.77825914 1.909307,9.78697674 2.47215086,10.3899269 L2.47215086,10.3899269 C2.84738009,10.7918671 3.81631033,11.5906445 5.37886182,12.7864983 L5.37886182,12.7864983 C6.6845894,14.1852757 7.44963923,15.0048638 7.67409106,15.2451827 L7.67409106,15.2451827 C7.89846315,15.4855814 8.40828375,16.0316811 9.20347312,16.8835615 L9.20347312,16.8835615 C10.0421974,17.2151761 10.5804034,17.2702724 10.8180113,17.0487708 L10.8180113,17.0487708 C11.0557788,16.8274286 11.0149548,16.3089169 10.6958585,15.4933953 L10.6958585,15.4933953 L9.50303458,14.2156545 C8.17043657,13.1481728 6.9068884,12.0320532 5.71239006,10.8672957 L5.71239006,10.8672957 C3.93583192,9.13506977 2.74372561,6.98998007 2.10928043,6.98998007 L2.10928043,6.98998007 C2.1038585,6.98998007 2.09851631,6.98998007 2.09317411,6.99037874 L2.09317411,6.99037874 Z" id="Clip-8"></path>
                <path d="M9.40496149,0.34261794 C8.92488176,1.36233887 9.25155285,3.26822591 9.20522727,4.15375415 L9.20522727,4.15375415 C9.17421066,4.74402658 9.27698807,6.08962126 9.51332029,8.19053821 L9.51332029,8.19053821 C9.4056791,10.2448904 9.34260933,11.4485581 9.32403126,11.8016213 L9.32403126,11.8016213 C9.30553292,12.1546047 9.26351298,12.9566512 9.19797146,14.2077608 L9.19797146,14.2077608 C9.56171896,15.1033355 9.91557943,15.5602924 10.2596326,15.5783123 L10.2596326,15.5783123 C10.6036857,15.5962525 10.9698253,15.1772492 11.3578917,14.3209834 L11.3578917,14.3209834 L11.4562837,12.4442791 C11.2911542,10.61701 11.2139715,8.80664452 11.2247356,7.01318272 L11.2247356,7.01318272 C11.2408419,4.32310963 12.0152206,1.77823256 11.5231808,1.31928239 L11.5231808,1.31928239 C11.1493867,0.970684385 10.3983701,-2.66453526e-15 9.84852295,-2.66453526e-15 L9.84852295,-2.66453526e-15 C9.67430368,-2.66453526e-15 9.52041664,0.0973554817 9.40496149,0.34261794 L9.40496149,0.34261794 Z" id="Clip-5"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Download.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Download;
