import React, { useState, useEffect } from "react";
import Input from "../../components/Input";
import ConsentEditor from "../consent/ConsentEditor";

const ProcedureNewStepFour = (props) => {
  const { setEditData, editData, sendData, setStep, totalSteps } = props;

  const [customConsent, setCustomConsent] = useState({ body: editData.consent || '' });

  const updateConsent = (body_content) => {
    setEditData({
      ...editData,
      consent_enabled: true,
      consent: body_content,
    })
  }

  return (
    <div style={{ maxWidth: 768 }}>
      <ConsentEditor
        value={customConsent}
        hideButton={true}
        getContent={(body_content) => updateConsent(body_content)}
      />

      <div style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: 'fit-content',
        gap: 24,
        marginInline: 'auto',
      }}>
        <button
          type="button"
          className="c-button c-button--grey c-modal__button"
          style={{ marginInline: "auto", marginTop: 40 }}
          onClick={() => setStep(totalSteps === 4 ? 3 : 2)}
        >
          <span className="c-button__text">Atrás</span>
        </button>
        <button
          type="button"
          className="c-button c-modal__button"
          style={{ marginInline: "auto", marginTop: 40 }}
          onClick={() => sendData() }
        >
          <span className="c-button__text">Enviar</span>
        </button>
      </div>
    </div>
  );
};

export default ProcedureNewStepFour;
