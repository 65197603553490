import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import Input from "../../components/Input";
import Datepicker from "../../components/Datepicker";

const ProcedureNewStepOne = (props) => {
  const { editData, setStep, setEditData, addStep, removeStep } = props

  const [switchPublicOpen, setSwitchPublicOpen] = useState(editData.public);
  const [switchConsentOpen, setSwitchConsentOpen] = useState(editData.consent_enabled);
  const [switchLicenseOpen, setSwitchLicenseOpen] = useState(editData.licenses);
  const [name, setName] = useState("")
  const [disabledButton, setDisabledButton] = useState(true);

  const nameRef = useRef();

  useEffect(() => {
    if(nameRef.current.props.value && nameRef.current.props.value.length > 0 ) {
      setDisabledButton(false)
    }else {
      setDisabledButton(true)
    }
  }, [nameRef.current, name])

  const handlePublicCheck = (checked) => {
    setSwitchPublicOpen(checked);
    setEditData({ ...editData, ["public"]: checked });
  }

  const handleLicensesCheck = (checked) => {
    setSwitchLicenseOpen(checked);

    if(checked) {
      setEditData({ ...editData, ["licenses"]: checked });
      addStep(1)
    } else {
      removeStep(1)
      // Remove Licenses properties from editData
      removeProperties([
        "num_licenses",
        "price_by_license",
        "email_admin",
        "email_reports",
        "reports_password",
        "licenses"
      ])
    }
  }

  const handleConsentCheck = (checked) => {
    setSwitchConsentOpen(checked);

    if (checked) {
      setEditData({ ...editData, ["consent_enabled"]: checked });
      addStep(1)
    } else {
      removeStep(1)

      // Remove Consent proerties from editData
      removeProperties([
        "consent",
        "consent_enabled",
      ])
    }
  }

  const removeProperties = (propertiesToRemove) => {
    const udpatedData = Object.keys(editData)
    .filter( key => !propertiesToRemove.includes(key))
    .reduce( (acc, key) => {
      acc[key] = editData[key]
      return acc
    }, {})
    setEditData(udpatedData)
  }


  return (
    <form style={{ maxWidth: 768 }}>
      <Input
        ref={nameRef}
        name="name"
        label="*Name"
        errorLabel="This field can not be blank"
        minCharacters={5}
        type="text"
        value={editData.name}
        onChange={(e) => {
          setName(e.currentTarget.value)
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }}
      />

      <Input
        name="description"
        label="Description"
        errorLabel="This field can not be blank"
        minCharacters={5}
        type="textarea"
        value={editData.description}
        onChange={(e) =>
          setEditData({
            ...editData,
            [e.currentTarget.name]: e.currentTarget.value,
          })
        }
      />

      <div className="c-modal-create__datepickers">
        <Datepicker
          isRequired={false}
          hasTime={true}
          value={editData.start_date || new Date()}
          label={"Start date"}
          errorLabel={""}
          onChange={(dateValue) =>
            setEditData({ ...editData, ["start_date"]: dateValue })
          }
          wrapperClassName="c-modal-create__datepicker"
        />
        <Datepicker
          isRequired={false}
          hasTime={true}
          value={editData.end_date || new Date()}
          label={"End date"}
          errorLabel={""}
          onChange={(dateValue) =>
            setEditData({ ...editData, ["end_date"]: dateValue })
          }
          wrapperClassName="c-modal-create__datepicker"
        />
      </div>

      <div>
        <div className="c-switch__container" key={"switch_open_close"}>
          <label className="c-switch__label" style={{ minWidth: 100 }}>Public</label>
          <Switch
            onChange={(checked) => handlePublicCheck(checked)}
            checked={switchPublicOpen}
            className={`c-switch c-switch-`}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1dd1a1"
            offColor="#121a22"
          />
          <p className="c-switch__text">
            Mediante la activación de este switch podrás hacer público este
            proceso, lo que ipsum dolor sit amet, consectetur adipiscing elit.
            Maecenas sed dapibus ante. Morbi tempor laoreet euismod. Donec iaculis
            nunc a volutpat dapibus.{" "}
          </p>
        </div>
        <div className="c-switch__container" key={"switch_open_close_license"}>
          <label className="c-switch__label" style={{ minWidth: 100 }}>Licencias</label>
          <Switch
            onChange={ (checked) => handleLicensesCheck(checked) }
            checked={switchLicenseOpen}
            className={`c-switch c-switch-`}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1dd1a1"
            offColor="#121a22"
          />
          <p className="c-switch__text">
            Activando esta opción se habiltará el paso para crear un licencia. Lorem ipsum dolor sit amet. Maecenas sed dapibus ante. Morbi tempor laoreet euismod. Donec iaculis
            nunc a volutpat dapibus.{" "}
          </p>
        </div>
        <div className="c-switch__container" key={"switch_open_close_consent"}>
          <label className="c-switch__label" style={{ minWidth: 100 }}>Consent</label>
          <Switch
            onChange={(checked) => {
              handleConsentCheck(checked)
            }}
            checked={switchConsentOpen}
            className={`c-switch c-switch-`}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1dd1a1"
            offColor="#121a22"
          />
          <p className="c-switch__text">
            Activando esta opción se habiltará el paso para crear un consentimiento. Lorem ipsum dolor sit amet. Maecenas sed dapibus ante. Morbi tempor laoreet euismod. Donec iaculis
            nunc a volutpat dapibus.{" "}
          </p>
        </div>
      </div>

      <button
        type="button"
        className="c-button c-modal__button"
        style={{ marginInline: 'auto', marginTop: 40 }}
        disabled={disabledButton}
        onClick={() => setStep(2)}
      >
        <span className="c-button__text">Continuar</span>
      </button>
    </form>
  );
};

export default ProcedureNewStepOne;
