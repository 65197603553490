import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Logo = (props) => {

  let logoClasses = classNames("c-logo", props.className);

  return (
    <svg 
      width={props.width ? props.width : "47px"} 
      height={props.height ? props.height : "48px"} 
      viewBox="0 0 47 48" 
      className={logoClasses}
    >
      <g
        id="Styleguide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Logo-Copy-7"
          transform="translate(-2412.000000, -1211.000000)"
          fill="#121A22"
        >
          <g id="Logo-T3" transform="translate(2412.000000, 1211.000000)">
            <path
              d="M20.9458418,33.8332163 L44.4186512,33.8253045 C46.0517079,33.824754 47.3760082,35.1481619 47.3765586,36.7812186 C47.3765588,36.7818831 47.3765588,36.7825476 47.3765586,36.7832121 C47.3760076,38.4176928 46.0511368,39.7425635 44.4166561,39.7431144 L20.9438468,39.7510262 C19.3107901,39.7515766 17.9864898,38.4281688 17.9859394,36.7951121 C17.9859391,36.7944476 17.9859391,36.7937831 17.9859394,36.7931186 C17.9864904,35.1586379 19.3113612,33.8337672 20.9458418,33.8332163 Z"
              id="Rectangle-Copy-6"
              transform="translate(32.681249, 36.788165) rotate(-143.000000) translate(-32.681249, -36.788165) "
            ></path>
            <path
              d="M1.82400651,33.8332173 L25.2968257,33.8253055 C26.9298797,33.824755 28.2541778,35.1481606 28.2547282,36.7812146 C28.2547284,36.7818791 28.2547284,36.7825436 28.2547282,36.7832082 C28.2541772,38.4176861 26.9293086,39.7425546 25.2948307,39.7431055 L1.82201145,39.7510173 C0.188957464,39.7515677 -1.13534058,38.4281621 -1.13589102,36.7951081 C-1.13589125,36.7944436 -1.13589125,36.7937791 -1.13589102,36.7931146 C-1.13534,35.1586367 0.189528567,33.8337682 1.82400651,33.8332173 Z"
              id="Rectangle-Copy-6"
              transform="translate(13.559419, 36.788161) scale(-1, 1) rotate(-143.000000) translate(-13.559419, -36.788161) "
            ></path>
            <path
              d="M20.9174414,8.26042007 L44.3921807,8.2585595 C46.0265133,8.25842997 47.3515066,9.58321326 47.3516361,11.2175458 C47.3516361,11.2177022 47.3516361,11.2178585 47.3516361,11.2180149 C47.3515065,12.8526824 46.0263791,14.1778099 44.3917115,14.1779395 L20.9169722,14.1798 C19.2826397,14.1799296 17.9576464,12.8551463 17.9575168,11.2208137 C17.9575168,11.2206574 17.9575168,11.220501 17.9575168,11.2203446 C17.9576464,9.5856771 19.2827739,8.26054963 20.9174414,8.26042007 Z"
              id="Rectangle-Copy-6"
              transform="translate(32.654576, 11.219180) rotate(-143.000000) translate(-32.654576, -11.219180) "
            ></path>
            <path
              d="M16.5178566,10.1300968 L29.3484876,10.1300968 C30.9824177,10.1300968 32.3069798,11.4546588 32.3069798,13.088589 C32.3069798,14.7225191 30.9824177,16.0470812 29.3484876,16.0470812 L16.5178566,16.0470812 C14.8839264,16.0470812 13.5593644,14.7225191 13.5593644,13.088589 C13.5593644,11.4546588 14.8839264,10.1300968 16.5178566,10.1300968 Z"
              id="Rectangle-Copy-8"
              transform="translate(22.933172, 13.088589) rotate(-90.000000) translate(-22.933172, -13.088589) "
            ></path>
            <path
              d="M1.79704515,8.26042007 L25.2717845,8.2585595 C26.906117,8.25842997 28.2311103,9.58321326 28.2312399,11.2175458 C28.2312399,11.2177022 28.2312399,11.2178585 28.2312399,11.2180149 C28.2311103,12.8526824 26.9059828,14.1778099 25.2713153,14.1779395 L1.79657597,14.1798 C0.16224343,14.1799296 -1.16274988,12.8551463 -1.16287941,11.2208137 C-1.16287942,11.2206574 -1.16287942,11.220501 -1.16287941,11.2203446 C-1.16274984,9.5856771 0.162377628,8.26054963 1.79704515,8.26042007 Z"
              id="Rectangle-Copy-6"
              transform="translate(13.534180, 11.219180) scale(-1, 1) rotate(-143.000000) translate(-13.534180, -11.219180) "
            ></path>
            <path
              d="M-4.04047569,32.5922903 L12.4545035,32.5922903 C14.0884463,32.5922903 15.4130187,33.9168627 15.4130187,35.5508056 C15.4130187,37.1847484 14.0884463,38.5093208 12.4545035,38.5093208 L-4.04047569,38.5093208 C-5.67441853,38.5093208 -6.99899092,37.1847484 -6.99899092,35.5508056 C-6.99899092,33.9168627 -5.67441853,32.5922903 -4.04047569,32.5922903 Z"
              id="Rectangle-Copy-7"
              transform="translate(4.207014, 35.550806) rotate(-90.000000) translate(-4.207014, -35.550806) "
            ></path>
            <path
              d="M33.7662773,32.592287 L50.2612498,32.592287 C51.8951945,32.592287 53.2197683,33.9168609 53.2197683,35.5508056 C53.2197683,37.1847502 51.8951945,38.5093241 50.2612498,38.5093241 L33.7662773,38.5093241 C32.1323326,38.5093241 30.8077587,37.1847502 30.8077587,35.5508056 C30.8077587,33.9168609 32.1323326,32.592287 33.7662773,32.592287 Z"
              id="Rectangle-Copy-3"
              transform="translate(42.013764, 35.550806) rotate(-90.000000) translate(-42.013764, -35.550806) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

Logo.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Logo;

