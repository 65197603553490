import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";

// Components
import Aside from "../../components/Aside";
import BubbleBg from "../../components/BubbleBg";
import EmailsFilterShow from "../../components/EmailsFilterShow";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import EmailShow from "../../components/EmailShow";
import Roles from "../../config/roles";
import Arrow from "../../components/Arrow";
import ModalInput from "../../components/admin-modals/ModalInput";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsShow,
  fetchEmailsTestSend,
  fetchEmailsDestroyFromOrganization,
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const EmailsShow = (props) => {
  const [email, setEmail] = useState([]);
  const [optionSend, setOptionSend] = useState({});
  const [showModalSend, setShowModalSend] = useState({});
  const [modals, setModals] = useState("");
  const [modalSession, setModalSession] = useState(false)

  useEffect(() => {
    props.fetchEmailsShow(
      props.match.params.organizationId,
      props.location.state.fromFolder
        ? props.location.state.fromFolder.folderId
        : "",
      props.location.state.emailId,
      (data) => setEmail(data)
    );

    props.organizations.length === 0 && props.fetchOrganizations();

    props.organization === undefined &&
    props.fetchOrganization(props.match.params.organizationId, 1, 10);

  }, []);

  const sendEmail = (option) => {
    props.fetchEmailsTestSend(
      props.match.params.organizationId,
      props.location.state.emailId,
      option,
      () => {
        setModals({});
        setShowModalSend({
          text: `The email has been successfully sent.`,
          type: "success",
          action: () => { 
            setShowModalSend({});
          },
        })
      },

      () =>
        {
          setModals({});
          setShowModalSend({
            text: `The email couldn't be sent.`,
            type: "error",
            action: () => {
              setShowModalSend({});
              setModals({});
            },
          })
        }
    );
  };

  const openModalDelete = () => {
    setShowModalSend({
      text: "Do you want to delete the email?",
      action: () => deleteEmail(),
    });
  };

  const deleteEmail = () => {
    let folderId = props.location.state.fromFolder
      ? props.location.state.fromFolder.folderId
      : "";
    props.fetchEmailsDestroyFromOrganization(
      props.match.params.organizationId,
      folderId,
      props.location.state.emailId,
      (data) =>{
        if(props.location.state.fromOwnerableType == "Procedure"){
          props.history.push(`/admin/organizations/${props.match.params.organizationId}/procedures/${props.location.state.fromOwnerableId}/emails`)
        }
        else{
          props.history.push(`/admin/organizations/${props.match.params.organizationId}/emails`)  
        }
      }
    );
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => setModals("createOrganization")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate(
      { organization: data, from_organization: props.match.params.organizationId },
      () =>
        setShowModalSend({
          text: "The organization has been created successfully",
          type: "success",
          action: () => {
            setShowModalSend({});
          },
        }),
      props.fetchOrganizations()
    );
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}/emails`);
  };

   // Logout
   const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const goBackToEmails = () => {
    if(props.location.state.fromOwnerableType == "Procedure"){
      props.history.push({pathname: `/admin/organizations/${props.match.params.organizationId}/procedures/${props.location.state.fromOwnerableId}/emails`})
    }
    else{
      props.history.push({pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.location.state.fromOwnerableId}/emails`})
    }
  }

  const sendTestModal = () => {
      setModals({
          text: "",
          type: "send_email",
          action: () => {
            setShowModalSend({});
            setOptionSend({});
          },
        })
  }
  return (
    <motion.section
      className="o-wrapper o-wrapper--row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <BubbleBg />

      {props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => setModalSession(true)}
          />
        )}

      <div className="o-container">
        <h1 className="o-margin--bottom-size-xl">Show email</h1>
          <span style={{cursor: 'pointer'}} onClick={() => goBackToEmails()} className="o-text-align o-text-align--center o-margin--top-size-xxl o-margin--bottom-size-xl">
            Go back to emails<Arrow widht={20} height={20} className="c-arrow-button-svg" />              
          </span>
        <div className="c-email">

          <EmailShow
            email={email}
            edit={() =>
              props.history.push({
                pathname: `/admin/organizations/${props.match.params.organizationId}/emails/new`,
                state: {
                  editEmail: email,
                  fromFolder: {
                    folderId:
                      props.location.state.fromFolder &&
                      props.location.state.fromFolder.folderId,
                  },
                },
              })
            }
            delete={() => {
              openModalDelete();
            }}
            canManage={
              props.organization &&
              props.organization.user_role !== Roles.admin.READER
            }
            sendTestModal={() => sendTestModal()}
          />
        </div>
      </div>
      {["createOrganization"].includes(modals) && (
        <ModalCreate
          showModal={["createOrganization"].includes(modals)}
          closeModal={() => setModals("")}
          editAction={(data) => submitCreateOrganization(data)}
          type={modals}
          title={"New Organization:"}
          assessments={props.assessments}
          biodataOptions={props.organization.data.biodata_options}
        />
      )}
      {Object.keys(showModalSend).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={showModalSend.type}
          closeModalInfoAction={() => setShowModalSend({})}
          text={showModalSend.text}
          action={() => showModalSend.action()}
        />
      )}
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
      {modalSession && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={false}
          closeModalInfoAction={() => setModalSession(false)}
          text={"Are you sure you want to logout?"}
          action={() => closeSession()}
        />
      )}
      {["send_email"].includes(modals.type) && (
        <ModalInput
          title={"Send test email"}
          description="Enter your email in order to send you a test email."
          data={{}}
          showModal={true}
          closeModal={() => setModals("")}
          action={(data, id) => sendEmail(data,id)}
          type={"user"}
          edit={true}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}      
    </motion.section>
  );
};

const mapStateToProps = (state, props) => {
  return {
    organizations: getOrganizations(state, props),
    organization: getOrganization(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsShow,
  fetchEmailsTestSend,
  fetchEmailsDestroyFromOrganization,
  fetchLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsShow);
