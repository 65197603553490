import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationLineSeparator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration c-illustration--separator",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width={this.props.width ? this.props.width : "931px"}
        height={this.props.height ? this.props.height : "19px"}
        preserveAspectRatio="none"
        viewBox="0 0 931 19"
      >
        <g
          id="Styleguide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Illustration"
            transform="translate(-59.000000, -1126.000000)"
            fill="#121a22"
          >
            <path
              d="M59.7065217,1138.43716 C76.8795084,1132.91598 94.8050015,1130.15538 113.483001,1130.15538 C132.161,1130.15538 213.896686,1132.91598 358.690058,1138.43716 C463.939035,1139.57208 525.275422,1140.13954 542.699219,1140.13954 C568.834914,1140.13954 657.405931,1138.75271 716.96875,1138.43716 C776.531569,1138.12161 790.835938,1134.29627 823.382812,1134.29627 C855.929687,1134.29627 889.257812,1130.15538 889.257812,1130.15538 C889.257812,1130.15538 914.625,1124.31211 939.625,1128.453 C964.625,1132.59389 980.46582,1126.75062 986.859375,1128.453 C991.121745,1129.58792 990.074219,1130.78621 983.716797,1132.04785 C961.264974,1133.5468 946.567708,1134.29627 939.625,1134.29627 C929.210937,1134.29627 919.570312,1129.67835 823.382812,1140.13954 C789.08691,1143.86951 784.509987,1144.0935 710.929687,1144.70412 C668.265039,1145.05818 572.955469,1145.05818 425.000976,1144.70412 C400.630534,1144.70412 383.970052,1144.70412 375.019531,1144.70412 C361.59375,1144.70412 363.357506,1141.40639 283.476972,1138.43716 C281.308993,1138.35657 218.41693,1138.52581 216.242187,1138.43716 C138.287158,1135.25953 117.496906,1128.61159 89.1015625,1134.29627 C69.6432292,1138.19179 59.8448822,1139.57208 59.7065217,1138.43716 Z"
              id="Path-4"
            ></path>
          </g>
        </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
