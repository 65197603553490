import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import classNames from "classnames";

//Components
import Chart from "./Chart";

const Highchart = (props) => {
  const classHighchart = () => {
    return classNames("c-charts__chart", props.classNames && props.classNames);
  };
  const seriesChart = (data) => {
    return data.data.map((item, index) => {
      return {
        name: item.status_type,
        data: item.data ? Object.values(item.data) : 0,
        color: item.color,
      };
    });
  };

  return (
    <div className={classHighchart()}>
      <div className="c-charts__chart-info">
        <Chart width={"28px"} height={"38px"} />
        <h3 className="c-charts__chart-info-title">{props.title}</h3>
      </div>

      <div className="c-charts__chart-wrapper">
        <HighchartsReact
          containerProps={{ className: "c-charts__chart-item" }}
          highcharts={Highcharts}
          options={{
            chart: {
              type: props.type,
            },
            title: {
              text: "",
            },
            plotOptions: {
              series: {
                borderRadius: 5,
              },
            },
            yAxis: {
              title: {
                text: "",
              },
            },
            xAxis: {
              categories: props.categories,
              title: {
                text: null,
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              style: {
                padding: 0,
              },
            },
            series:
              props.type === "spline"
                ? [
                    {
                      showInLegend: false,
                      name: props.title,
                      data: props.data[0].data,
                    },
                  ]
                : seriesChart(props.data[0]),
          }}
        />
        {
          props.totalUsers !== undefined && props.totalUsers !== "" &&
          <p className="c-charts__chart-info-legend">{`Total users: ${props.totalUsers}`}</p>
        }
        
      </div>
    </div>
  );
};

export default Highchart;
