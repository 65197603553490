import PropTypes from "prop-types";
import React from "react";
import anime from "animejs/lib/anime.es.js";

export default class Curtain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isActive !== this.props.isActive) {
      return true;
    } else {
      return false;
    }
  }

  openCurtain() {
    document.body.classList.add("has-curtain");

    anime({
      targets: ".c-curtain__path",
      d: [
        {
          value:
            "M2.49739854,0.25 L0,0.75 C302.351717,0.75 622.185224,0.75 959.50052,0.75 C1296.81582,0.75 1616.98231,0.75 1920,0.75 L1920,0.25 L2.49739854,0.25 Z",
        },
        {
          value:
            "M0,0 L0,713.5625 C302.078666,418 621.995311,270.21875 959.749935,270.21875 C1297.50456,270.21875 1617.58791,418 1920,713.5625 L1920,0 L0,0 Z",
        },
        {
          value:
            "M0.999739651,0 L0,1080 C302.421244,1080 622.421244,1080 960,1080 C1297.57876,1080 1617.57876,1080 1920,1080 L1920,0 L0.999739651,0 Z",
        },
      ],
      easing: "linear",
      duration: 950,
      loop: false,
    });
  }

  closeCurtain() {
    document.body.classList.remove("has-curtain");

    anime({
      targets: ".c-curtain__path",
      d: [
        {
          value:
            "M0.5,0.5 L-0.5,1080 C302,1080 622.083333,1080 959.75,1080 C1297.41667,1080 1617.5,1080 1920,1080 L1920,0.5 L0.5,0.5 Z",
        },
        {
          value:
            "M0.5,0.5 L-0.5,1080 C302.166667,632.229167 622.333333,408.34375 960,408.34375 C1297.66667,408.34375 1617.66667,632.229167 1920,1080 L1920,0.5 L0.5,0.5 Z",
        },
        {
          value:
            "M2,0 L-0.5,0.5 C302.166667,0.5 622.333333,0.5 960,0.5 C1297.66667,0.5 1618.16667,0.5 1921.5,0.5 L1921.5,0 L2,0 Z",
        },
      ],
      easing: "linear",
      duration: 950,
      loop: false,
    });
  }

  render() {
    this.props.isActive ? this.openCurtain() : this.closeCurtain();
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="none"
        className="c-curtain"
      >
        <path
          d="M2,0 L-0.5,0.5 C302.166667,0.5 622.333333,0.5 960,0.5 C1297.66667,0.5 1618.16667,0.5 1921.5,0.5 L1921.5,0 L2,0 Z"
          className="c-curtain__path"
          fill="#121a22"
        ></path>
      </svg>
    );
  }

  static propTypes = {
    isActive: PropTypes.bool.isRequired,
  };
}
