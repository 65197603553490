import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//Components
import Eye from "./Eye";
import Search from "./Search";

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      showError: false,
      errorLabel: null,
      slashed: false,
      hintLabel: null,
      showHint: false,
    };
    this.input = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    state.showError = props.showError;
    state.errorLabel =
      props.errorLabel === null || !Boolean(JSON.parse(state.value.length))
        ? state.errorLabel
        : null;
    state.showHint = props.showHint;
    state.hintLabel =
      props.hintLabel === null || !Boolean(JSON.parse(state.value.length))
        ? state.hintLabel
        : null;

    return state;
  }

  componentDidMount = () => {
    this._introKey();
  };

  _setStateEmptyField = (show, value) => {
    this.setState({
      showError: show,
      errorLabel:
        value && value.length ? null : "Este campo no puede estar vacío",
    });
  };

  _introKey = () => {
    let form = this.input.current.closest("form");
    let formButton = form.querySelector(".c-button");

    this.input.current.addEventListener("keyup", (event) => {
      event.preventDefault();
      event.keyCode === 13 && formButton && formButton.click();
    });
  };

  _handleOnChange = (event) => {
    const value = event.currentTarget.value;
    this.setState({ value: value });

    if (this.props.isRequired)
      this._setStateEmptyField(
        this.props.isRequired && !Boolean(JSON.parse(value.length)),
        value
      );

    if (value.length && this.props.onChangeValidation) {
      this.props.onChangeValidation(value);
    }

    this.props.onChange && this.props.onChange(event, value);
  };

  _handleOnBlur = (event, state) => {
    if (state.value.length && this.props.onBlurValidation) {
      this.props.onBlurValidation(state.value);
    } else {
      if (this.props.isRequired)
        this._setStateEmptyField(this.props.isRequired, state.value);
    }

    this.props.onBlur && this.props.onBlur(event);
  };

  _handleEye = (state) => {
    this.setState({ slashed: !state.slashed });
  };

  _handleClasess = () => {
    let inputWrapperClasses = classNames(
      "c-input__wrapper",
      this.props.wrapperClassName && this.props.wrapperClassName,
      this.props.type === "textarea" && "c-input__wrapper--textarea",
      this.props.fullWidth && "c-input__wrapper--full-width",
      this.state.showHint &&
        this._hintLabel(this.state.hintLabel) !== null &&
        "c-input__wrapper--has-hint",
      this.state.showError &&
        this._errorLabel(this.state.errorLabel) !== null &&
        "c-input__wrapper--has-error"
    );

    let inputClasses = classNames(
      "c-input",
      this.props.className && this.props.className,
      this.props.disabled && "o-disabled",
      this.props.type === "textarea" && "c-input__textarea",
      this.props.type === "range" && "c-input__range"
    );
    let labelClasses = classNames(
      "c-input__label",
      this.props.disabled && "o-disabled"
    );

    return { inputWrapperClasses, inputClasses, labelClasses };
  };

  _errorLabel = (error) => {
    return this.props.errorLabel === null && error != null
      ? error
      : this.props.errorLabel || error;
  };

  _hintLabel = (hint) => {
    return this.props.hintLabel === null && hint != null
      ? hint
      : this.props.hintLabel || hint;
  };

  _handleKeyPress(e){
    this.props.onKeyPress && this.props.onKeyPress(event)
  }

  render() {
    const { inputWrapperClasses, inputClasses, labelClasses } = {
      ...this._handleClasess(),
    };

    return (
      <div className={inputWrapperClasses}>
        <label className={labelClasses}>
          {
            this.props.isRequired &&
              <span className="o-color--red">*{" "}</span>
          }
          {this.props.label} 
        </label>
        {this.props.type !== "textarea" && (
          <input
            ref={this.input}
            name={this.props.name}
            required={this.props.isRequired}
            data-cursor-hover
            type={this.state.slashed ? "text" : this.props.type}
            placeholder={this.props.placeholder}
            className={inputClasses}
            onChange={(event) => this._handleOnChange(event)}
            onBlur={(event) => this._handleOnBlur(event, this.state)}
            defaultValue={this.props.value}
            value={this.props.value}
            data-min-characters={this.props.minCharacters}
            disabled={this.props.disabled}
            onKeyPress={(event) => this._handleKeyPress(event)}
            style={this.props.type === 'color' ? {backgroundColor: this.props.value} : {}}
          />
        )}

        {this.props.type === "textarea" && (
          <textarea
            ref={this.input}
            name={this.props.name}
            required={this.props.isRequired}
            data-cursor-hover
            type={this.state.slashed ? "text" : this.props.type}
            placeholder={this.props.placeholder}
            className={inputClasses}
            onChange={(event) => this._handleOnChange(event)}
            onBlur={(event) => this._handleOnBlur(event, this.state)}
            defaultValue={this.props.value}
            data-min-characters={this.props.minCharacters}
            disabled={this.props.disabled}
            maxLength={this.props.maxCharacter ? this.props.maxCharacter : ""}
            onKeyPress={(event) => this._handleKeyPress(event)}
          ></textarea>
        )}

        {this.props.type === "password" && (
          <div
            className="c-input__icon"
            onClick={() => this._handleEye(this.state)}
          >
            <Eye slashed={this.state.slashed} />
          </div>
        )}
        {this.props.search && (
          <div className="c-input__icon">
            <Search />
          </div>
        )}
        {this.state.showHint &&
          this._hintLabel(this.state.hintLabel) !== null && (
            <span className="c-input__hint">
              {this._hintLabel(this.state.hintLabel)}
            </span>
          )}

        {this.state.showError &&
          this._errorLabel(this.state.errorLabel) !== null && (
            <span className="c-input__error">
              {this._errorLabel(this.state.errorLabel)}
            </span>
          )}
      </div>
    );
  }

  static propTypes = {
    ref: PropTypes.string,
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    errorLabel: PropTypes.string,
    showError: PropTypes.bool,
    hintLabel: PropTypes.string,
    showHint: PropTypes.bool,
    isRequired: PropTypes.bool,
    minCharacters: PropTypes.number,
    onChange: PropTypes.func,
    onChangeValidation: PropTypes.func,
    onBlur: PropTypes.func,
    onBlurValidation: PropTypes.func,
    disabled: PropTypes.bool,
  };
}
