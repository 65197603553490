// Dependencies
import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import BubbleBg from "../components/BubbleBg";
import IllustrationWebPage from "../components/IllustrationWebPage";
import LinkAnchor from "../components/LinkAnchor";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <BubbleBg />
        <div className="o-container o-display--flex@lg-and-up">
          <div className="o-col--1/2@lg">
            <h1 className="o-margin--bottom-size-l">
              ¡Hola, <br /> Eugenio!
            </h1>
            <h3 className="o-margin--bottom-size-l">
              ¿Quieres echar un{" "}
              <LinkAnchor
                className="c-link"
                href={routes.client.getData}
                text="vistazo a tus pruebas"
              />
              ?
            </h3>
            <p>
              Odio eveniet aut iure ut dolorem. Earum et aliquam veniam
              molestias officiis eum. Aut voluptatem maxime. Ipsa commodi rerum
              et expedita quo asperiores velit.
            </p>
          </div>
          <div className="o-col--1/2@lg o-display--flex o-display--flex-h-right u-hide@sm-and-down o-margin--top-size-xxl@md-only">
            <IllustrationWebPage />
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {};
}
