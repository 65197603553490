// Dependencies
import React from "react";
import PropTypes from "prop-types";

const Search = (props) => {
  return (
    <svg width="16px" height="24px" viewBox="0 0 16 24">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15.294084,8.44477771 C15.2186303,11.1908284 14.1321323,13.2909041 12.2368961,14.4610311 C12.1813503,14.4953253 12.422585,15.3145983 12.844311,16.4495468 C13.2827429,17.629454 13.0077438,16.9342709 13.5576478,18.1523948 C14.908558,21.1448735 16.1442279,23.2126002 15.986342,23.281058 C15.9217608,23.3090598 15.3551504,24.5355012 14.9190863,23.7214194 C14.4717595,22.8863114 13.9515689,21.8570549 13.4274452,20.8064538 L13.0648092,20.0778282 C12.7835879,19.5117706 12.5058384,18.9510866 12.2423222,18.4227547 L12.0201879,17.978451 C11.2203172,16.3830027 10.591408,15.1832516 10.4675404,15.2167117 C7.16563994,16.1085282 3.1739773,14.6666337 1.51010202,11.5706423 C0.632990722,9.93920776 0.161290656,7.95496107 0.0171956271,6.08969191 C-0.170665387,3.65786303 1.19555,1.86006599 3.38999187,0.799826706 C7.93832713,-1.39687989 15.4069542,0.861118168 15.294084,8.44477771 Z M13.9989527,8.24457936 C14.0924248,2.03807652 7.90736744,0.190120223 4.14071602,1.98791529 C2.32341428,2.85562014 1.19199876,4.32694574 1.34757369,6.317166 C1.46690433,7.8437111 1.8575373,9.46762798 2.58390692,10.8028017 C3.96182605,13.3365757 7.26747545,14.516629 10.0019063,13.7867621 C12.4699097,13.1279219 13.9190457,11.1185223 13.9989527,8.24457936 Z"
          id="Combined-Shape"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
