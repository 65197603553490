import PropTypes from "prop-types";
import React from "react";

export default class Arrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        onClick={() => this.props.action && this.props.action()}
        width={this.props.width ? this.props.width : "47px"}
        height={this.props.height ? this.props.height : "37px"}      
        viewBox="0 0 47 37"
        className={this.props.className}
      >
        <g
          id="Arrow"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Arrow group"
            transform="translate(-502.000000, -745.000000)"
            fill={this.props.color ? this.props.color : "#121A22"}
            className='filled'
          >
            <g id="Texts" transform="translate(223.000000, 135.000000)">
              <g id="Group-3" transform="translate(0.000000, 188.000000)">
                <g
                  id="Buttons-illustration"
                  transform="translate(160.000000, 408.000000)"
                >
                  <g id="Group-7" transform="translate(112.000000, 0.000000)">
                    <g id="Group-4" transform="translate(7.000000, 14.000000)">
                      <path
                        d="M45.0445747,37.1657386 C43.3691167,35.9260266 42.2573069,35.4454802 41.6303164,34.5970092 C38.235738,30.0165148 34.9607957,25.3472182 31.6606679,20.6969519 C31.3566283,20.2695591 31.0144848,19.7529304 31.0068383,19.2690541 C30.9781697,18.0275046 31.1261525,16.7800744 31.1984201,15.5324359 C32.2910724,15.9301649 33.4088577,16.2897639 34.4764651,16.7574557 C34.8302252,16.911225 35.1341768,17.306785 35.3433789,17.6638852 C38.3229306,22.7525595 41.3213759,27.8285527 44.1938227,32.9742311 C44.7011065,33.8860381 44.647715,35.1146289 45.0445747,37.1657386 Z"
                        id="Path"
                        transform="translate(38.023888, 26.349087) rotate(72.000000) translate(-38.023888, -26.349087) "
                      ></path>
                      <path
                        d="M45.2254314,20.901847 C43.5499734,19.6621351 42.4381636,19.1815887 41.8111731,18.3331177 C38.4165947,13.7526233 35.1416525,9.08332666 31.8415246,4.43306035 C31.537485,4.00566759 31.1953415,3.48903882 31.187695,3.00516255 C31.1590264,1.76361303 31.3070093,0.516182816 31.3792768,-0.731455629 C32.4719292,-0.333726631 33.5897144,0.0258723891 34.6573218,0.493564188 C35.0110819,0.647333482 35.3150336,1.04289341 35.5242356,1.39999367 C38.5037873,6.48866797 41.5022327,11.5646612 44.3746794,16.7103396 C44.8819633,17.6221465 44.8285718,18.8507373 45.2254314,20.901847 Z"
                        id="Path"
                        transform="translate(38.204744, 10.085196) scale(1, -1) rotate(72.000000) translate(-38.204744, -10.085196) "
                      ></path>
                      <path
                        d="M13.1757779,9.66743807 C22.295407,10.6842826 31.6229778,10.7596666 40.0139962,15.3329619 C41.5489545,16.164441 42.9814085,16.9962405 44.3103804,18.1419669 C45.5371738,19.1834782 47.480846,20.4318181 46.5524409,22.3163409 C45.6237098,24.3053992 43.4740513,23.3713067 41.9377894,22.9579697 C30.9782961,20.2743228 19.704446,19.7868832 8.63951536,18.0443765 C8.33232817,17.940802 7.92198485,18.0466189 7.61479766,17.9430444 C5.05479578,17.1147687 1.46942442,16.3942321 1.47853182,13.467237 C1.48865164,10.2266353 5.27903171,10.9465312 7.63826138,10.4164856 C9.48464338,9.99257716 11.3300477,9.88227538 13.1757779,9.66743807 Z"
                        id="Path"
                        transform="translate(24.137058, 16.604369) scale(-1, 1) rotate(-11.000000) translate(-24.137058, -16.604369) "
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
