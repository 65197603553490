import PropTypes from "prop-types";
import React from "react";

export default class IllustrationShapes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        className="c-illustration c-illustration--shapes"
        width="400px"
        height="450px"
        viewBox="0 0 400 450"
      >
        <g
          id="Test-Home-(Instructions)"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="01_Destkop_without_modules"
            transform="translate(-811.000000, -294.000000)"
          >
            <g id="Group-22" transform="translate(811.000000, 293.000000)">
              <path
                d="M109.484792,0.951736541 C82.7022998,18.4784725 56.9336358,35.3141518 31.2117312,52.2161554 C28.5591996,53.9598431 25.2095283,55.6243696 23.9236456,58.206739 C22.5846273,60.8939436 21.8811113,65.8896624 23.4241705,67.7488828 C52.8953265,103.24309 82.7299304,138.43349 112.539029,173.645284 C114.277627,175.701338 116.441311,177.395818 118.894053,179.717169 C141.140886,156.311103 163.030648,133.647442 184.480446,110.566579 C196.397709,97.7403115 196.623004,96.0608086 183.745049,83.9469929 C158.616138,60.3055827 135.102551,35.3184308 115.070411,7.10706115 C113.450837,4.82849991 111.299906,2.93076861 109.484792,0.951736541 L109.484792,0.951736541 Z"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M194.880124,247.913451 C194.83475,216.544825 175.722585,186.654608 145.923915,176.033899 C132.047931,171.088442 116.537057,169.635126 101.672726,169.049617 C73.2309742,167.926695 51.8229608,184.233111 44.4783052,210.438806 C36.3656214,239.381754 47.0953664,274.800846 70.5130193,286.705491 C89.3101487,296.259738 110.083973,301.809524 130.001229,309.076104 C130.91545,309.410681 132.043813,309.132564 133.071283,309.151384 C168.384107,309.764077 194.931526,283.464283 194.880124,247.913451 L194.880124,247.913451 Z"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M218.271605,450.37037 C188.719552,391.406639 147.323771,344.590799 113.011322,291.358025 C67.5768768,340.194895 34.3491841,395.149127 0,450.37037 L218.271605,450.37037 Z"
                id="Path"
                fill="#121A22"
              ></path>
              <g
                id="Group-5"
                transform="translate(16.790123, 13.827160)"
                fill="#FFFFFF"
              >
                <path
                  d="M182.814237,427.296224 L0.300339326,427.296224 C29.0205619,381.564346 56.806632,336.051797 94.7979123,295.608981 C123.489225,339.692297 158.103462,378.464976 182.814237,427.296224"
                  id="Fill-10"
                ></path>
                <path
                  d="M168.565447,231.552201 C168.610439,262.0051 145.373273,284.533546 114.463678,284.008712 C113.564326,283.992591 112.576661,284.230826 111.776436,283.944227 C94.3427039,277.719663 76.1592114,272.965707 59.7059352,264.781524 C39.2100805,254.583986 29.8164476,224.243935 36.9175439,199.45136 C43.346379,177.00352 62.0849827,163.035418 86.9802728,163.997314 C99.9911394,164.498862 113.567931,165.743775 125.713689,169.980061 C151.796701,179.077778 168.52573,204.681785 168.565447,231.552201"
                  id="Fill-12"
                ></path>
                <path
                  d="M97.368609,5.17070337 C114.161938,28.8693112 133.873832,49.8594553 154.939883,69.7191259 C165.735722,79.8951851 165.546853,81.3060305 155.556381,92.0805757 C137.574601,111.469365 119.223991,130.50769 100.574041,150.169663 C98.5178607,148.219641 96.704003,146.796215 95.2465023,145.069052 C70.2569594,115.489825 45.2460351,85.928571 20.5397961,56.1121063 C19.2462197,54.5502915 19.8359908,50.3537005 20.9585157,48.0963479 C22.036496,45.9270608 24.8445901,44.5287962 27.0682585,43.0640332 C48.6314277,28.8657167 70.2337962,14.723115 92.6860764,0 C94.2077213,1.66246111 96.0108884,3.25662652 97.368609,5.17070337 Z"
                  id="Fill-14"
                ></path>
              </g>
              <path
                d="M384.398219,386.931007 C375.625744,377.130999 368.324931,368.166239 360.034026,360.192451 C357.455471,357.709711 351.655072,356.175474 348.282287,357.212455 C336.074135,360.962224 324.19841,365.816219 312.36042,370.647209 C309.00201,372.016874 306.202437,374.719043 301.787449,377.741508 C317.873315,389.985325 332.611505,401.399203 347.667746,412.393687 C349.346053,413.618245 353.728697,413.519148 355.324346,412.216727 C364.948552,404.361502 374.125331,395.973629 384.398219,386.931007"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M287.87224,378.785569 C291.156977,375.584374 294.655545,370.55519 299.487414,367.76985 C313.860833,359.479305 328.660118,351.88067 343.49534,344.400599 C350.609275,340.811864 357.288359,342.052349 362.997117,348.014109 C374.659011,360.19599 386.479032,372.225686 399.233442,385.347221 C390.867066,392.383124 383.327266,398.507687 376.044422,404.910076 C370.058942,410.172847 363.983616,415.425 358.677365,421.319516 C354.690039,425.748808 351.13397,425.449747 347.130473,422.308718 C331.748992,410.241861 316.532826,397.964422 301.063297,386.007279 C297.760592,383.451986 293.656468,381.903592 287.87224,378.785569"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M262.405874,201.243784 C281.145289,200.979754 289.989931,193.74966 289.520993,178.580569 C289.191468,167.932547 285.190105,158.628187 277.419133,151.188316 C268.780896,142.920185 256.101464,141.180477 244.577176,146.726925 C233.798844,151.915304 229.560296,161.140093 229.810155,172.55308 C230.190375,190.123762 243.307966,201.515048 262.405874,201.243784"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M262.179552,209.889876 C234.555663,210.457723 218.296731,191.861661 222.873856,165.776901 C224.146688,158.514256 221.923306,151.195549 229.643583,145.343478 C239.509387,137.862012 250.361952,134.512804 262.123425,133.726138 C278.251996,132.646507 292.865118,145.414006 298.472458,164.47845 C306.43173,191.54157 292.260387,209.273203 262.179552,209.889876"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M302.983072,263.588177 C287.333059,271.645296 272.584261,279.240508 256.876339,287.326609 C272.91724,298.508398 287.32944,308.554437 302.983072,319.466327 L302.983072,263.588177 Z"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M302.199486,325.369688 C299.218962,323.993022 294.417911,322.210602 290.051181,319.67645 C275.785565,311.389282 261.650245,302.87569 247.525782,294.347606 C241.814469,290.898695 241.48149,286.55314 246.027377,281.776471 C246.852586,280.910621 247.694081,279.883556 248.743689,279.428894 C267.95513,271.087384 286.992842,262.27491 306.54269,254.819177 C313.947853,251.9952 314.997461,255.101756 314.362267,263.247633 C313.012255,280.56102 312.867481,297.970411 312.228668,315.339952 C312.031415,320.725252 311.67491,325.976508 302.199486,325.369688 L302.199486,325.369688 Z"
                id="Path"
                fill="#121A22"
              ></path>
              <path
                d="M384.60761,386.001461 C374.168107,396.141014 364.842493,405.546391 355.062193,414.354507 C353.440664,415.81492 348.986939,415.926039 347.281413,414.552933 C331.980977,402.224745 317.003752,389.426288 300.656992,375.697215 C305.143586,372.308105 307.988565,369.278144 311.401444,367.74233 C323.431432,362.325308 335.499767,356.882492 347.90592,352.677852 C351.333408,351.515077 357.227883,353.235428 359.848258,356.01934 C368.273632,364.960401 375.692855,375.012647 384.60761,386.001461"
                id="Fill-16"
                fill="#FFFFFF"
              ></path>
              <path
                d="M261.82326,200.656581 C242.620193,200.927969 229.430373,189.531495 229.04806,171.95281 C228.796825,160.534625 233.058712,151.305634 243.896393,146.114892 C255.484136,140.565918 268.233385,142.306418 276.919187,150.578315 C284.732949,158.021575 288.756345,167.330172 289.087683,177.983044 C289.559203,193.159044 280.66586,200.39243 261.82326,200.656581"
                id="Fill-18"
                fill="#FFFFFF"
              ></path>
              <path
                d="M302.967295,263.039492 L302.967295,318.486752 C287.279944,307.659006 272.836699,297.690434 256.761245,286.594871 C272.503003,278.571123 287.283571,271.03448 302.967295,263.039492"
                id="Fill-20"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  static propTypes = {};
}
