import {call, takeLatest, all, put, putResolve} from 'redux-saga/effects';
import {FETCH_SCORES, FETCH_INVITATION, FETCH_INVITATION_REGISTRATION, FETCH_SHOW_PROFILE, FETCH_UPDATE_PROFILE, fetchUpdateProfileOk} from './actions';
import { fetchLoginOk, fetchLogout } from "../login/actions";
import { redirectTo } from "../../actions";
import {FETCH_PROCEDURE_CLIENT, POST_PROCEDURE_CLIENT, POST_ANSWER_SCORE} from '../procedures/actions';

import Api from '../../services/api';
import { setErrors } from "../../actions";

// Config
import routes from '../../config/routes';

function * fetchScoresSaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.scores.indexScores, {}, {});

    if(response.status) {
      action.data.callback(response.data);
    };
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * fetchFolderInvitationSaga(action) {
  try {
    // yield putResolve(fetchLogout());
    const response = yield call(Api.handleGet, routes.folderInvitations.showInvitation(action.data.token), {}, {})

    if(response.status) {
      action.data.success(response)
    } else {
      action.data.error(response)
    }
  } catch(error) {
    // yield putResolve(redirectTo(routes.client.home));
  }
}

function * fetchFolderRegistrationSaga(action) {
  try {
    const response = yield call(Api.handlePost, routes.folderInvitations.createInvitation(action.data.token), {}, action.data.attributes);

    if(response.status) {
      yield put(fetchLoginOk(response));
      action.data.success();
    } else {
      if (response.data){
        yield put(setErrors('invitationRegistration', response.data.attributes.errors))  
      }
      action.data.error(response)
    }
  } catch (error) {
    // yield putResolve(redirectTo(routes.client.home));
  }
}

function * fetchProfileShowSaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.users.showProfile(action.data.userId), {}, {})

    if(response.status) {
      yield put(fetchUpdateProfileOk(response.data.attributes));
      action.data.success(response.data.attributes)
    }
  } catch(error) {
    action.data.error()
    // yield putResolve(redirectTo(routes.client.home));
  }
}

function * fetchProfileUpdateSaga(action) {
  try {
    const response = yield call(Api.handlePut, routes.users.updateProfile(action.data.userId), {}, action.data.profile)

    if(response.status) {
      yield put(fetchUpdateProfileOk(response.data.attributes));
      action.data.success()
    }
    else{
      action.data.error()
    }
  } catch(error) {
      action.data.callback(response.data);
      action.data.error()
  }
}

function * fetchProcedureClientSaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.client.getProcedureClient(action.data.slug), {}, {});

    if(response.status) {
      action.data.success(response);
    }
    else{
     action.data.error(response); 
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * postProcedureClientSaga(action) {
  try {
    const response = yield call(Api.handlePost, routes.client.createProcedure, {}, action.data);

    if(response.status) {
      action.data.success(response);
    };
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * postAnswerScoreSaga(action) {

  try {
    const response = yield call(Api.handlePost, routes.procedures.setAnswerScore(action.data.organization_id, action.data.folder_id, action.data.user_folder_id), {}, action.data);
    if(response.status) {
      action.data.success(response);
    };
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}


export function * watchUsers() {
  yield all([
    takeLatest(FETCH_SCORES, fetchScoresSaga),
    takeLatest(FETCH_INVITATION, fetchFolderInvitationSaga),
    takeLatest(FETCH_INVITATION_REGISTRATION, fetchFolderRegistrationSaga),
    takeLatest(FETCH_SHOW_PROFILE, fetchProfileShowSaga),
    takeLatest(FETCH_UPDATE_PROFILE, fetchProfileUpdateSaga),
    takeLatest(FETCH_PROCEDURE_CLIENT, fetchProcedureClientSaga),
    takeLatest(POST_PROCEDURE_CLIENT, postProcedureClientSaga),
    takeLatest(POST_ANSWER_SCORE, postAnswerScoreSaga),
    
  ])
}
