// Dependencies
import React from "react";
import classNames from "classnames";

const IllustrationProcedure = (props) => {
	let classNameProcedure = classNames("c-procedure", props.className && props.className);

	return (
    <div style={props.style ? props.style : {}}>
		<svg
			className={classNameProcedure}
			width={props.width ? props.width : "40px"}
			height={props.height ? props.height : "35px"}
			viewBox="0 0 40 35" 
		>
			<defs>
				<path d="M31.4972694,18.3511633 C30.6417345,18.8856749 29.745874,19.3511323 28.8033624,19.7451633 L28.8033624,19.7451633 C27.3914399,20.3381865 25.6026182,20.3213183 25.6553314,20.798109 L25.6553314,20.798109 C25.7117345,21.2754269 25.5926027,22.4883571 26.4104477,22.6233028 L26.4104477,22.6233028 C27.2275019,22.7593028 28.0266337,22.0827292 28.517657,21.9187912 L28.517657,21.9187912 C28.8431608,21.8102021 29.4830988,21.43014 30.4379981,20.779923 L30.4379981,20.779923 C31.5721221,20.4003881 32.2378895,20.1776749 32.4331919,20.1133648 L32.4331919,20.1133648 C32.6274399,20.0461555 33.0702306,19.8972408 33.7618275,19.6663571 L33.7618275,19.6663571 C33.7734244,19.6423726 33.7873934,19.617861 33.7992539,19.5938765 L33.7992539,19.5938765 C34.0145872,19.153985 34.0290833,18.8063416 33.8443236,18.5554269 L33.8443236,18.5554269 C33.6474399,18.2897524 33.209657,18.1068377 32.5351919,18.0048377 L32.5351919,18.0048377 L31.4972694,18.3511633 Z" id="path-1"></path>
			</defs>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="A4" transform="translate(-280.000000, -404.000000)">
					<g id="Procedure" transform="translate(280.627961, 404.201101)">
						<g id="Gear" transform="translate(7.828491, 7.250594)" fill="#121A22" fillRule="nonzero">
							<path d="M9.28114201,2.47184675 C10.6408593,2.47184675 12.0817,3.21303426 13.1909177,3.99090796 C13.8578556,4.4586191 14.2688295,4.62015304 14.7122991,5.30943889 C15.1557687,5.99872474 15.9088961,6.80506737 15.9088961,7.29315063 C15.9088961,8.6504271 16.2067727,8.94527399 16.2067727,9.37742329 C16.2067727,9.96155244 16.0091095,10.6535002 15.6137831,11.4532666 C15.4476806,12.3324914 15.1471859,13.0002355 14.7122991,13.456499 C13.9056376,14.3028115 12.5662032,14.9581912 11.2935544,15.551715 C10.0209055,16.1452388 8.9079408,15.8508837 8.16817734,15.8508837 C7.42841386,15.8508837 5.96910022,15.3834888 4.77808576,14.1632351 C3.97488391,13.3403147 3.24991902,12.5729 2.87679532,11.4532666 C2.64029132,10.7435882 2.82033603,10.1547415 2.82033603,9.04956517 C2.82033603,7.94438882 2.85605445,7.48450534 3.21117926,6.7033905 C3.60793902,5.83069745 3.90491228,4.99664965 4.77808576,4.35381452 C5.4810886,3.83625996 6.534954,2.95072434 7.44748472,2.7333051 C7.95689367,2.61193352 8.7353339,2.47184675 9.28114201,2.47184675 Z M9.3183766,4.83443649 C8.72091848,4.83443649 8.21239924,4.78791102 7.72979842,5.03481889 C7.3422087,5.23311725 6.96669876,5.70002762 6.61028859,5.93306439 C6.04510485,6.30260654 5.40060842,6.47889022 5.11057888,7.14319658 C4.86280164,7.7107249 4.81299316,8.75433824 4.81299316,9.4152815 C4.81299316,10.2255915 5.10250398,10.6687471 5.39085629,11.3110024 C5.812265,12.2496181 6.39645258,13.2696086 7.43541256,13.6429803 C7.90514367,13.8117878 8.79179235,13.9961265 9.3183766,13.9961265 C9.75618651,13.9961265 10.1827926,13.776849 10.6231866,13.6429803 C11.0535584,13.512158 11.4985233,13.4696592 11.858909,13.2191457 C12.5659673,12.727651 13.0638096,12.0102143 13.4405057,11.3110024 C13.7919692,10.6586266 13.82376,10.2115847 13.82376,9.4152815 C13.82376,8.73298252 13.1127467,7.70414384 12.7865735,7.14319658 C12.2564617,6.23151897 11.9002353,5.90211074 10.9879691,5.43587944 C10.3802372,5.1252863 10.0449351,4.83443649 9.3183766,4.83443649 Z" id="Oval"></path>
							<path d="M8.49567355,1.36772301 C8.67682479,3.42628089 7.6221227,3.19757926 8.37126868,3.52897879 C8.57996961,3.62130178 8.72417217,3.78462429 9.79616891,3.78462429 C10.8681657,3.78462429 11.5197656,3.81556002 11.5197656,3.52897879 C11.5197656,3.24239758 11.5197656,1.94696651 11.5197656,1.26774587 C11.5197656,0.814932118 11.3098381,0.525168024 10.8899831,0.398453598 C9.17300924,-0.327738723 8.37490605,-0.00464892009 8.49567355,1.36772301 Z" id="Path-8" transform="translate(9.809444, 1.903557) rotate(90.000000) translate(-9.809444, -1.903557) "></path>
							<path d="M8.30720338,15.5854677 C8.48835462,17.6440256 7.43365253,17.415324 8.18279851,17.7467235 C8.39149944,17.8390465 8.535702,18.002369 9.60769874,18.002369 C10.6796955,18.002369 11.3312954,18.0333047 11.3312954,17.7467235 C11.3312954,17.4601423 11.3312954,16.1647112 11.3312954,15.4854906 C11.3312954,15.0326768 11.1213679,14.7429127 10.7015129,14.6161983 C10.0635014,14.72019 9.64407503,14.7538576 9.44323379,14.7172009 C8.45780496,14.537345 8.21471202,14.5344188 8.30720338,15.5854677 Z" id="Path-8" transform="translate(9.620974, 16.309771) rotate(90.000000) translate(-9.620974, -16.309771) "></path>
							<path d="M0.693856018,8.41521165 C0.875007259,10.4737695 -0.179694836,10.2450679 0.569451151,10.5764674 C0.778152078,10.6687904 0.922354634,10.8321129 1.99435138,10.8321129 C3.06634812,10.8321129 3.71794805,10.8630487 3.71794805,10.5764674 C3.71794805,10.2898862 3.71794805,8.99445514 3.71794805,8.31523451 C3.71794805,7.86242076 3.50802056,7.57265666 3.08816558,7.44594224 C1.37119171,6.71974992 0.573088521,7.04283972 0.693856018,8.41521165 Z" id="Path-8" transform="translate(2.007627, 8.951045) rotate(90.000000) translate(-2.007627, -8.951045) "></path>
							<path d="M15.314963,8.25316375 C15.4961142,10.3117216 14.4414121,10.08302 15.1905581,10.4144195 C15.3992591,10.5067425 15.5434616,10.670065 16.6154584,10.670065 C17.6874551,10.670065 18.339055,10.7010008 18.339055,10.4144195 C18.339055,10.1278383 18.339055,8.83240724 18.339055,8.15318661 C18.339055,7.70037285 18.1291275,7.41060876 17.7092726,7.28389433 C17.3178649,7.25559248 17.0458955,7.23812264 16.8933643,7.23148483 C16.8448871,7.22937521 15.2224716,7.20211481 15.314963,8.25316375 Z" id="Path-8" transform="translate(16.628734, 8.951045) rotate(90.000000) translate(-16.628734, -8.951045) "></path>
							<path d="M2.80343489,3.31247986 C2.98458613,5.37103776 1.92988404,5.14233612 2.67903003,5.47373565 C2.88773095,5.56605862 3.03193351,5.72938113 4.10393025,5.72938113 C5.17592698,5.72938113 5.82752694,5.76031686 5.82752694,5.47373565 C5.82752694,5.18715442 5.82752694,3.89172335 5.82752694,3.21250272 C5.82752694,2.75968897 5.61759942,2.46992488 5.19774444,2.34321045 C3.48077058,1.61701813 2.6826674,1.94010793 2.80343489,3.31247986 Z" id="Path-8" transform="translate(4.117206, 3.848313) rotate(135.000000) translate(-4.117206, -3.848313) "></path>
							<path d="M13.4216702,13.3204773 C12.3482582,15.125535 12.5481193,15.1503336 13.2972653,15.4817331 C13.5059662,15.5740561 13.6501688,15.7373786 14.7221655,15.7373786 C15.7941623,15.7373786 16.4457622,15.7683143 16.4457622,15.4817331 C16.4457622,15.1951519 16.4457622,13.8997208 16.4457622,13.2205002 C16.4457622,12.7676864 16.2358347,12.4779223 15.8159797,12.3512079 C15.1890781,12.6572187 14.7752066,12.7918958 14.5743654,12.7552392 C13.5889366,12.5753832 13.6414888,12.9508287 13.4216702,13.3204773 Z" id="Path-8" transform="translate(14.560739, 14.044781) rotate(135.000000) translate(-14.560739, -14.044781) "></path>
							<path d="M2.63479902,13.7432468 C2.81595026,15.8018047 2.46524219,15.8593506 2.51039416,15.9045026 C2.67997665,16.0740851 3.05166818,16.4171361 3.51968342,16.3475559 C3.98769866,16.2779757 3.65427041,15.9445475 4.38811556,15.8775588 C5.12196073,15.8105702 4.84848144,16.2026427 5.65889105,15.9045026 C6.46930068,15.6063626 6.03268694,14.0170656 5.65889105,13.6432697 C5.40969379,13.3940724 5.1997663,13.1043083 5.02910857,12.7739774 C3.31213472,12.0477851 2.51403153,12.3708749 2.63479902,13.7432468 Z" id="Path-8" transform="translate(4.311752, 14.376890) rotate(45.000000) translate(-4.311752, -14.376890) "></path>
							<path d="M13.1303352,3.00661538 C13.3114864,5.06517327 12.2567843,4.83647163 13.0059303,5.16787116 C13.2146312,5.26019413 13.3588338,5.42351663 14.4308305,5.42351663 C15.5028273,5.42351663 16.1544272,5.45445237 16.1544272,5.16787116 C16.1544272,4.88128996 16.1544272,3.58585887 16.1544272,2.90663824 C16.1544272,2.45382449 15.9444997,2.16406039 15.5246448,2.03734597 C14.8866332,2.14133767 14.4672068,2.17500521 14.2663656,2.1383486 C13.2809368,1.95849266 13.0378438,1.95556644 13.1303352,3.00661538 Z" id="Path-8" transform="translate(14.444106, 3.730919) rotate(45.000000) translate(-14.444106, -3.730919) "></path>
						</g>
						<g id="Arrow">
							<path d="M9.85284518,1.53289922 C9.62459712,1.62646512 9.29593046,1.75086822 8.90558937,1.90110078 C6.88482968,2.69258915 3.29043059,5.21164781 2.49630656,7.25059354 C2.19161102,8.03291172 0.831458189,11.4455552 0.434054487,13.7318728 C-0.204289933,17.4043552 -0.0840153494,19.2785505 0.441281865,22.2269837 C0.791480008,24.1926058 1.61280009,25.9843999 2.90524211,27.602366 L8.90558937,31.6392313 C11.6307902,32.5818401 13.6411793,33.1248902 14.9367568,33.2683816 C19.9589919,33.8246182 22.0412028,31.5335579 26.6372922,30.617991 C27.9520001,30.3560938 29.2627062,29.5248034 31.0612886,27.4292811 C32.675341,25.548754 33.1856956,23.9104545 33.469207,22.7122293 C33.9742119,20.5778897 33.5201532,18.5239037 33.469207,18.2644085 C33.3912247,17.8672048 32.7114824,18.4326944 31.4299799,19.9608775 C31.7946029,21.498675 31.6717057,22.7425468 31.0612886,23.6924929 C30.2182775,25.0044074 29.4443418,25.7343861 27.9765312,26.7781473 C23.3834497,30.0442909 17.6381481,30.617991 16.0363456,30.617991 C13.4939215,30.617991 10.7038785,29.7993303 9.83611746,29.4426981 C4.48618731,27.2439848 4.47066498,23.8775408 4.15597453,23.2562636 C2.65361018,20.2902228 3.33903542,16.5068438 3.39767375,14.8347611 C3.45631207,13.1626784 4.36812454,10.0243501 4.64449992,9.32587191 C5.84077232,6.30255572 7.32685466,5.47060653 8.40217852,4.73337984 C12.1664266,3.86282171 12.7280855,3.04866667 15.4367522,2.68415504 C17.2395429,2.43877519 18.8952638,2.57978295 20.4041785,3.11113178 C24.094101,5.31796899 28.1292948,8.55956589 28.7745041,10.2703721 C29.4160235,11.9788062 30.7296359,12.340155 32.7153413,11.354155 C33.7648607,10.8046202 33.2646126,9.47308527 31.2132793,7.3563876 C28.132194,4.1812093 23.6125661,1.35314729 21.8247987,0.76275969 C19.7044111,0.205844961 17.9116359,2.13162821e-14 16.4014033,2.13162821e-14 C15.2232638,2.13162821e-14 14.2172328,0.125193798 13.3609072,0.307054264 C11.6295429,0.675255814 10.5167677,1.27381395 9.85284518,1.53289922 Z" id="Clip-2" fill="#12181F"></path>
							<path d="M32.6804763,17.5505319 C32.4483604,17.6950499 32.3490481,18.0741477 32.3831202,18.6866618 L32.3831202,18.6866618 L32.9528592,19.6931676 C33.6766969,20.5537592 34.3364559,21.4455351 34.9348464,22.3689606 L34.9348464,22.3689606 C35.8305931,23.7538661 36.1815741,25.4031394 36.6589701,25.4173352 L36.6589701,25.4173352 C37.0446039,25.4294366 37.9500302,25.6109567 38.2655839,25.2898057 L38.2655839,25.2898057 C38.2828135,25.2702574 38.3010111,25.2488473 38.3145625,25.2276699 L38.3145625,25.2276699 C38.3575397,25.1597163 38.3801899,25.0738433 38.3761245,24.9681892 L38.3761245,24.9681892 C38.3476666,24.2318691 37.4904445,23.4001346 37.2232886,22.9267859 L37.2232886,22.9267859 C37.0438295,22.6109874 36.5234562,21.9679873 35.6592649,20.9975527 L35.6592649,20.9975527 C35.0389985,19.8986577 34.6733046,19.2535631 34.5666359,19.0652942 L34.5666359,19.0652942 C34.458612,18.876327 34.2162357,18.4457985 33.8391199,17.7765012 L33.8391199,17.7765012 C33.475362,17.5779927 33.1812969,17.4793202 32.9578926,17.4793202 L32.9578926,17.4793202 C32.8483199,17.4793202 32.7557833,17.5030574 32.6804763,17.5505319 L32.6804763,17.5505319 Z" id="Clip-5" fill="#12181F" transform="translate(35.376591, 21.479320) rotate(-8.000000) translate(-35.376591, -21.479320) "></path>
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1"></use>
							</mask>
							<use id="Clip-8" fill="#12181F" transform="translate(29.813424, 20.323041) rotate(-8.000000) translate(-29.813424, -20.323041) " xlinkHref="#path-1"></use>
						</g>
					</g>
				</g>
			</g>
		</svg>
</div>
	);
};

export default IllustrationProcedure;
